<template>
  <svg viewBox="0 0 589 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 9H588L580 1"
      stroke="#0178EC"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>
