export default {
  home: {
    services: '服務項目',
    services_subtitle: '蛻變是朝向理想的階段，理想是不斷完善的迭代',
    portfoilo_works: '作品案例',
    about_dailycreative: '關於日創',
    contact_us: '聯絡我們',
    hero_1: '設計出有溫度的產品',
    hero_2: '帶給使用者直覺順暢的操作體驗',
    hero_sub_1: '有溫度的設計源自於那些藏在我們所看不到的細節裡，',
    hero_sub_2: '探索用戶心中的需求，給予用戶最需要的設計才是有溫度的使用體驗',
    ux: 'UX 用戶體驗',
    ux_1: '- 需求歸納',
    ux_2: '- 用戶研究',
    ux_3: '- 使用體驗流程',
    ui: 'UI 介面設計',
    ui_1: '- 視覺風格定調',
    ui_2: '- 產品介面設計',
    hmi: 'HMI 人機介面設計',
    hmi_1: '- 軟硬體互動',
    hmi_2: '- 資訊設計',
    app_web_programming: 'APP/WEB 程式開發',
    app_web_programming_1: '- 前後端開發',
    app_web_programming_2: '- 建置＆串接資料庫',
    portfoilo_works_list: '精選作品',
    portfoilo_works_subtitle: '打造有溫度的產品，源自於藏在使用體驗的細節',
    portfoilo_works_learn_more: '了解更多',
    docs4docs_the_englis_name: 'Docs4Docs',
    docs4docs_the_englis_title: '專屬醫護人員的英文學習平台',
    docs4docs_the_englis_description:
      '整合多元學習類型與醫護教育資源，創造以醫護英文教育為核心的數位學習產品。協助臨床與研究人員在專業上的成長，更提供醫護人員與學者的交流平台。',
    systex_ecommerce_int_name: '精誠電商整合網站',
    systex_ecommerce_int_title: 'All-in-One 全方位電商服務平台',
    systex_ecommerce_int_description:
      '已在資訊產業服務多年的精誠資訊，以嶄新的視視樣貌，結合長期深耕電商與代營運的實務經驗，針對中小型電商推出all-in-one全方位整合平台，帶給使用者更智能便利的數位體驗。',
    pawbo_life_the_inter_name: 'Pawbo Life',
    pawbo_life_the_inter_title: '毛孩與主人的互動樂園',
    pawbo_life_the_inter_description:
      'Pawbo Life是一款寵物智能APP，只要與Crunchy寵物餵食機、Pawbo+寵物互動攝像機、iPuppyGo寵物智慧扣配對後，就可以隨時隨地於遠端與您的毛孩互動與餵食、同時能智慧紀錄毛孩每天的運動量...',
    readmore: '查看更多',
    testimonial_1_title: '好潤科技 / YC黄先生',
    testimonial_1_texts_1:
      '感謝日創團隊，不只是有溫度的科技體驗，更是有溫度的合作關係。非常專業和用心溝通，整個設計過程非常順利，最終設計也令人相當滿意！',
    testimonial_2_title: '高雄科技大學 / Jo Shan Fu 傅若珊',
    testimonial_2_texts_1: '專業服務、客製產品、高效率執行力',
    testimonial_2_texts_2:
      '團隊的專業知識相當多元，處理問題能力也非常精準，善於解決客戶可能有的問題，並符合其需求，在執行力上也展現極高的效率，超級推薦！',
    testimonial_3_title: '嗨投資 / 鄭總經理',
    testimonial_3_texts_1:
      '日創是一群會替客戶著想與思考的開發團隊，尤其是同樣身為工作內容同質性很高的我們，開發過程中十分感謝日創團隊的大力相挺與協助，讓我們的產品可以順利開發。因此我們也已經決定將未來的開發專案繼續委託給日創團隊，非常感謝日創的幫忙～如果您有也相關的開發需求，可以放心地交給他們喔！！',
    testimonial_4_title: 'Pawbo Inc. / Vock Zheng',
    testimonial_4_texts_1:
      '專案執行過程專業，團隊回應清晰明確且快速，對於顧客需求也能快速內化後，給予正確及專業的回應，是個不可多得的好團隊',
    testimonial_5_title: '恆聚科技 / Nick',
    testimonial_5_texts_1:
      '願意花時間從原始的需求了解做起，重視設計過程中的雙方的溝通協調，進而產出令人滿意甚至超越預期的成果',
    testimonial_6_title: '智瀚科技 / Ting Huang',
    testimonial_6_texts_1:
      '謝謝 Andy 及團隊細心的態度、耐心的溝通、專業的建議、完整的服務！會持續保持合作！',
    testimonial_7_title: 'Pawbo Inc. / Danny Lee',
    testimonial_7_texts_1:
      '設計團隊經歷相當豐富，不僅清楚瞭解產品功能，並以使用者為中心的設計，讓產品有效解決使用者的使用問題。雙方合作相當愉快，期待能有下次合作機會！',
    testimonial_8_title: 'Garmin / Carol Kao',
    testimonial_8_texts_1: '專業，積極，品質，效率',
    testimonial_8_texts_2:
      '團隊認真的傾聽客戶的需求，在討論過程中，也用自身的專業和經驗，產生很棒的火花。執行專案非常的有效率，且品質令人驚豔，能感受到替客戶著想的用心，合作的非常愉快，謝謝。值得推薦的團隊。',
    collaborative_title: '合作夥伴',
    collaborative_subtitle: '為創造與改善而相遇，並攜手踏入多變的挑戰',
    contact_us_title: '想與日創聊聊？',
    contact_us_subtitle_1: '我們一起將想法落實，讓您的理想不再只是空談，',
    contact_us_subtitle_2: '為用戶更美好的生活邁進！',
  },
  header: {
    services: '服務項目',
    our_services: '服務說明',
    project_flow: '專案流程',
    works: '作品案例',
    about_us: '關於日創',
    contact_us: '聯絡我們',
    facebook: 'FACEBOOK',
  },
  about_us: {
    hero: '關於日創',
    hero_text: '我們希望能讓使用者從生活中的每一刻都能感受到有溫度的體驗',
    core_values: '核心理念',
    core_values_title_1: '設計出有溫度的產品',
    core_values_title_2: '帶給使用者直覺順暢的操作體驗',
    core_values_text_1: '與客戶一同創造商業價值，打造世界級的產品。',
    core_values_text_2:
      'DailyCreative = 人人都會有在日常中迸發靈感的一刻，我們的任務是運用',
    core_values_text_3:
      'UXUI & Development 將這些 creative idea 融於 daily life。',
    founding_tale_title: '成立故事',
    founding_tale_text_1:
      'DailyCreative日創設計成立於2019年，專精於APP、人機界面和RWD網頁設計開發，協助客戶創造新的服務模式，同時提升使用者對產品的黏著度。',
    founding_tale_text_2:
      '擁有專業的技術團隊及10年以上的科技產業經驗，針對客戶的個別需求，提供全方位的客製化服務，從前期產品規劃到後期產品維護和改版，實現客戶在行動裝置上的需求。',
    team_background_title: '團隊背景',
    team_background_text:
      '團隊擁有十年以上的科技產業及IoT軟硬整合開發經驗，建構無形的服務體驗，運用硬體科技的輔助，交融出完善的產品體驗。',
    tailor_made_services_title: '客製化服務',
    tailor_made_services_text:
      '針對客戶的個別需求，提供全方位的客製化服務，從前期產品規劃到後期產品維護和改版，實現客戶在網頁或行動裝置的需求。',
    commercial_values_title: '商業價值',
    commercial_values_text:
      '共同合作提升用戶與品牌的黏著度，締造持續性的商業價值，引領客戶突破瞬息萬變與充斥挑戰的商業環境，獲取成功。',
    strategic_partnership_title: '策略合作夥伴',
    strategic_partnership_text:
      '邁向設計落實為初衷，與各領域專業夥伴攜手協作，一起為產品目標付出行動',
  },
  contact_us: {
    contact_us_title: '聯絡我們',
    contact_us_text:
      '我們一起將想法落實，讓您的理想不再只是空談，為用戶更美好的生活邁進！',
    contact_information: '聯絡資訊',
    contact_window: '聯絡人',
    contact_window_placeholder: '請輸入聯絡人姓名',
    contact_phone: '電話',
    contact_phone_placeholder: '請輸入電話',
    contact_email: '信箱',
    contact_email_placeholder: '請輸入信箱',
    contact_email_error: '⚠ 請填寫有效的電子信箱',
    contact_company: '公司名稱',
    contact_company_placeholder: '請輸入公司名稱',
    contact_project_demand: '專案需求',
    contact_service_type: '服務類型',
    contact_service_type_ui_ux: 'UI/UX產品規劃設計',
    contact_service_type_app_development: 'APP開發',
    contact_service_type_website_design: '網站設計與架設',
    contact_service_type_other: '其他',
    contact_budget: '您的預算',
    contact_budget_50_below: '50萬以下',
    contact_budget_50_150: '50-150萬',
    contact_budget_150_above: '150萬以上',
    contact_budget_no_budget_concept: '無預算概念',
    contact_project_content: '專案內容',
    contact_project_content_placeholder:
      '請簡述您的專案內容細節，以幫助我們了解您的需求',
    contact_submit: '確認送出',
    contact_submit_success: '發送成功',
    contact_company_position_title: '想與日創聊聊？',
    contact_company_position_title_sub: '歡迎來一起落實理想，打造品牌價值',
    contact_address: '105台北市松山區民權東路3段144號12樓之5 (1430室)',
  },
  our_services: {
    hero_title: '服務項目',
    hero_sub_title:
      '全面性探求用戶需求，整合客戶目標提升產品黏著度，一同創造商業價值，開拓數位科技的藍圖',
    tailor_made_services: '客製化服務',
    service_1_title: 'UX 用戶體驗',
    service_1_subtitle: 'User Experience Design',
    service_2_title: 'UI 介面設計',
    service_2_subtitle: 'User Interface Design',
    service_3_title: 'APP 程式開發',
    service_3_subtitle: 'APP Development',
    service_4_title: '前端開發',
    service_4_subtitle: 'Front-End Development',
    service_5_title: '後端開發',
    service_5_subtitle: 'Back-End Development',
    service_6_title: '營運維護',
    service_6_subtitle: 'Operation & Maintenance',
    project_flow: '專案流程',
    project_flow_1_title: '專案籌劃',
    project_flow_1_item_1_title: '確認客戶需求與專案目標',
    project_flow_1_item_1_text: '需求訪談、行銷目的、專案目標',
    project_flow_1_item_2_title: '產品研究',
    project_flow_1_item_2_text: '整合目標用戶、市場、數據分析等資訊',
    project_flow_1_item_3_title: '專案規劃',
    project_flow_1_item_3_text: '專案/提案企劃報告、報價預算、時程規劃',
    project_flow_2_title: 'UX 用戶體驗',
    project_flow_2_item_1_title: '彙整產品功能列表',
    project_flow_2_item_1_text: '依據產品研究與客戶需求，羅列產品功能表',
    project_flow_2_item_2_title: '動線設計',
    project_flow_2_item_2_text: '依照產品主/次要功能，執行User Flow撰寫',
    project_flow_2_item_3_title: '使用體驗設計',
    project_flow_2_item_3_text: '繪製Wireframe呈現頁面、功能、內容階層規劃',
    project_flow_3_title: 'UI 介面設計',
    project_flow_3_item_1_title: '設計發想',
    project_flow_3_item_1_text: '主視覺風格提案與定調',
    project_flow_3_item_2_title: '使用者介面設計',
    project_flow_3_item_2_text: '主/延伸頁面Mockup實作',
    project_flow_3_item_3_title: '設計規範制定',
    project_flow_3_item_3_text: '設計原則、元件化文件，助於後續專案維運',
    project_flow_4_title: 'APP 程式開發',
    project_flow_4_item_1_title: '框架建立',
    project_flow_4_item_1_text: '採原生系統常用最新程式語言執行介面開發',
    project_flow_4_item_2_title: '程式功能撰寫',
    project_flow_4_item_2_text: '前後端程式開發與資料庫建置',
    project_flow_4_item_3_title: '整合測試',
    project_flow_4_item_3_text: '進行功能測試、效能最佳化及跨平台相容性驗證',
    project_flow_5_title: '前端開發',
    project_flow_5_item_1_title: '客製品牌網站',
    project_flow_5_item_1_text: '針對客戶需求與目標用戶，客製專屬網站',
    project_flow_5_item_2_title: '前後端程式開發',
    project_flow_5_item_2_text: '提供全面性程式開發，落實產品功能與效益',
    project_flow_5_item_3_title: '資料庫建置',
    project_flow_5_item_3_text: '助於人員管理與維護相關資源',
    project_flow_6_title: '後端開發',
    project_flow_6_item_1_title: 'API建構與設計',
    project_flow_6_item_1_text: '支援用戶端程式與伺服器資料之間的雙向溝通橋樑',
    project_flow_6_item_2_title: '系統資料庫建置',
    project_flow_6_item_2_text: '提供完整結構，有效滿足資料管理與維護相關資源',
    project_flow_6_item_3_title: '雲端平台部署',
    project_flow_6_item_3_text: '快速地做出合適的運算，提供對應資料給使用者',
    project_flow_7_title: '營運維護',
    project_flow_7_item_1_title: '產品驗收整合測試上線',
    project_flow_7_item_1_text: 'SIT系統整合測試、UAT使用者驗收測試',
    project_flow_7_item_2_title: '專案資源維護',
    project_flow_7_item_2_text: '彙整階段性檔案產出，交付客戶端所需文件',
    project_flow_7_item_3_title: '策劃後續產品優化',
    project_flow_7_item_3_text: '根據用戶回饋等需求，規劃產品更新計畫',
    browse_works: '作品瀏覽',
    learn_more: '了解更多',
    project_1_name: 'Docs4Docs',
    project_2_name: '精誠電商整合網站',
    project_3_name: 'Pawbo Life',
  },
  project_flow: {
    title: '專案流程',
    subtitle: '確實做好每一個階段，為的是下一階段更好的啟程',
    project_flow_1_title: 'UX 用戶體驗',
    project_flow_1_subtitle:
      '實現用戶需求為初衷，將龐雜繁複的思維梳理成理性直覺的交互體驗',
    project_flow_1_item_1_title: '用戶需求與研究',
    project_flow_1_item_1_text_1: `-  用戶訪談
          -  競品彙整`,
    project_flow_1_item_1_text_2: `-  市場定位
          -  特點與功能盤點`,
    project_flow_1_item_2_title: '整合研究與趨勢',
    project_flow_1_item_2_text_1: `-  競品利弊比較表
          -  競品特點分析`,
    project_flow_1_item_2_text_2: `-  任務分析
          -  功能優先列表`,
    project_flow_1_item_3_title: '解決方案/產品草圖',
    project_flow_1_item_3_text_1: `-  使用者旅程
          -  內容清單`,
    project_flow_1_item_3_text_2: `-  功能架構規劃
          -  User Flow`,
    project_flow_1_item_4_title: '原型實作',
    project_flow_1_item_4_text_1: '-  Wireframe',
    project_flow_1_item_4_text_2: '-  Prototypes',
    project_flow_1_item_5_title: '易用性測試',
    project_flow_1_item_5_text_1: '-  使用者驗收測試',
    project_flow_1_item_5_text_2: '-  產品易用性測試',
    project_flow_2_title: 'UI 介面設計',
    project_flow_2_subtitle:
      '視覺的揉合促使感官體驗昇華至美感的層次，交融出知與美的造物',
    project_flow_2_item_1_title: '主視覺提案與定調',
    project_flow_2_item_1_text_1: `-  競品畫面趨勢分析
-  風格提案`,
    project_flow_2_item_1_text_2: `-  UI Reference 歸納
-  品牌色彩規劃`,
    project_flow_2_item_2_title: 'Mockup實作',
    project_flow_2_item_2_text_1: `-  主延伸畫面繪製
-  流程說明插畫`,
    project_flow_2_item_2_text_2: `-  System Icons 設計
-  情境、實景圖應用`,
    project_flow_2_item_3_title: 'Prototypes與測試',
    project_flow_2_item_3_text_1: '-  互動式介面',
    project_flow_2_item_3_text_2: '-  任務績效執行',
    project_flow_2_item_4_title: '切圖輸出',
    project_flow_2_item_4_text_1: '-  設計元素圖檔輸出',
    project_flow_2_item_5_title: '流程文件交付',
    project_flow_2_item_5_text_1: '-  UX 流程說明文件',
    project_flow_3_title: 'APP 程式開發',
    project_flow_3_subtitle: '構建條理清晰的邏輯架構，落實功能細節的串接',
    project_flow_3_item_1_title: 'iOS 程式開發',
    project_flow_3_item_1_text_1:
      '採用Apple最新的語言Swift，打造專為iOS、Mac、Apple TV與Apple Watch的APP',
    project_flow_3_item_2_title: 'Android 程式開發',
    project_flow_3_item_2_text_1:
      '採用Android原生語言開發，隨時更新至最新的Android版本，打造專為Android Phone、Wear、Tablet、TV的APP',
    project_flow_3_item_3_title: 'Flutter 跨平台應用開發',
    project_flow_3_item_3_text_1:
      'Flutter是Google內部一款移動應用SDK，用於為Android和iOS平台構建應用程序。 使用Dart語言開發的跨平臺移動UI框架，透過自建繪製引擎，能高效能、高保真地進行雙平台開發。',
    project_flow_3_item_4_title: 'Kotlin 程式開發',
    project_flow_3_item_4_text_1:
      'Kotlin是一種現代的靜態程式設計語言，有超過60%的專業Android開發人員在使用，可提升工作效率、開發人員滿意度和程式碼安全性。',
    project_flow_4_title: '前端開發',
    project_flow_4_subtitle: '創建全方位客製網站服務，促進網站商業效益',
    project_flow_4_item_1_title: 'HTML5',
    project_flow_4_item_1_text_1:
      '廣義上來說，HTML5是指包含了HTML、CSS和JavaScript的一套技術組合，這三種技術也都有了創新，目的在於希望能夠減少網頁瀏覽器對於外掛程式的需要，比如Adobe Flash、Microsoft Silverlight與Oracle JavaFX。',
    project_flow_4_item_2_title: 'CSS',
    project_flow_4_item_2_text_1: '-  SASS',
    project_flow_4_item_2_text_2: '-  Bootstrap',
    project_flow_4_item_2_text_3: '-  Tailwind',
    project_flow_4_item_3_title: 'JavaScript',
    project_flow_4_item_3_text_1: '-  Vue',
    project_flow_4_item_3_text_2: '-  React',
    project_flow_4_item_3_text_3: '-  jQuery',
    project_flow_5_title: '後端開發',
    project_flow_5_subtitle: '創建全方位客製網站服務，促進網站商業效益',
    project_flow_5_item_1_title: 'C#',
    project_flow_5_item_1_text_1:
      'ASP.NET是微軟開發的網站語言，深受全球大型企業與政府機關喜愛，若貴公司本身系統採用.NET開發，或主機是Windows Server，就建議APP系統後台可以採用ASP.NET開發。',
    project_flow_5_item_2_title: 'ASP.NET / ASP.Net Core ',
    project_flow_5_item_2_text_1:
      'ASP.NET / ASP.Net Core是微軟開發的網站語言，深受全球大型企業與政府機關喜愛，若貴公司本身系統採.NET開發，或主機是Windows Server，就建議APP系統後台可採用ASP.NET / ASP.Net Core開發。',
    project_flow_5_item_3_title: 'Node.js',
    project_flow_5_item_3_text_1:
      'Node.js是一個高效能、易擴充的網站應用程式開發框架。讓開發者能更容易開發高延展性的網路服務，不需經過太多複雜的調校、效能調整及程式修改，就能滿足網路服務在不同發展階段對效能的要求。',
    project_flow_5_item_4_title: '資料庫建置',
    project_flow_5_item_4_text_1: `-  Microsoft SQL Server (MSSQL)
-  MySQL
-  Mongo DB`,
    project_flow_5_item_5_title: '雲端架構設計',
    project_flow_5_item_5_text_1: `-  Microsoft Azure
-  Amazon Web Services (AWS)
-  Google Cloud Platform (GCP)`,
    project_flow_5_item_6_title: 'API串接',
    project_flow_5_item_6_text_1:
      '我們有各種系統API串接的經驗，可以處理APP與原有網站系統或資料庫串接，網站可以客製化串接企業內部系統包括CRM或是ERP，我們有專業的工程師可以協助串接。',
    project_flow_6_title: '營運維護',
    project_flow_6_subtitle: '縝密驗證產品實用性，持續延展與淬鍊完善的用戶體驗',
    project_flow_6_item_1_title: '用戶回饋彙整',
    project_flow_6_item_1_text_1: '-  根據用戶反饋等建議彙整產品之優化方向',
    project_flow_6_item_2_title: '產品迭代計畫',
    project_flow_6_item_2_text_1: `-  評估衡量產品迭代之項目
-  持續維護與優化產品`,
  },
  project: {
    title: '作品案例',
    description:
      '擁有超過10年的技術與產業經驗，提供全方位的客製化服務，服務範圍涵蓋各大產業領域',
    project_1_title: 'Docs4Docs',
    project_1_description: '專屬醫護人員的英文學習平台',
    project_2_title: '精誠大電商服務網站',
    project_2_description: 'All-in-One 全方位電商整合平台',
    project_3_title: 'Pawbo Life',
    project_3_description: '專屬毛孩與主人的互動智能寵物樂園',
    project_4_title: 'Swiper 滑吧',
    project_4_description: '最創新有趣的地圖社群APP',
    project_5_title: 'HiStock 嗨投資',
    project_5_description: '與投資達人一起聰明快樂學投資',
    project_6_title: '就業金卡會商系統',
    project_6_description: '外國專業人才審核平台',
    project_7_title: 'URE BESS',
    project_7_description: '聯合再生能源儲存能源系統APP',
    project_8_title: 'Focoten',
    project_8_description: 'IoT雲端監控APP',
    project_9_title: '智瀚 IVI System',
    project_9_description: '智慧車載系統介面設計',
    project_10_title: '智瀚 Digital Instrument Cluster',
    project_10_description: '智慧車載系統介面設計',
    project_11_title: 'F10',
    project_11_description: '線上雲端監控平台',
    project_12_title: 'ConneXionONE.live',
    project_12_description: '企業線上視訊會議',
  },
  project_detail: {
    title: '專案介紹',
    view_all_works: '查看所有作品',
    learn_more: '了解更多',
    design_target: '設計目標',
  },
  Docs4Docs: {
    BannerDefault_subTitle: '國立高雄科技大學',
    BannerDefault_title: 'Docs4Docs',
    BannerDefault_description: '醫護英文自主學習平台',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `Docs4Docs提供最即時的自主學習平台，不只能優化口語醫護英文能力，也提供相關醫護新聞及發表論文，協助臨床與研究人員在專業上的成長，更提供醫護人員與學者交流平台，近一步拉近各地醫護相關人員的距離，形成零距離的醫護交流圈。

      支援平台：iOS / Android`,
    SummarySection_title: '打造最即時的醫護英文學習平台',
    SummarySection_description:
      '醫護人員尤其是護理人員及護生對於提升醫護英文能力有極大的需求，但目前市面上尚未開發過相關APP產品，因此我們與客戶攜手從零開始著手規劃，自用戶需求歸納、到建立資訊架構乃至設計策略層，將多元的學習類型與醫護教育資源整合，創造以醫護英文教育為核心的學習平台。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '護理人員',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#醫療教育',
    SummarySection_secBlockConfig_items_1_title: '整合多元模組',
    SummarySection_secBlockConfig_items_1_description:
      '因應不同學習類型與教學資源，整合平台所需元素，設計適合學習的模組架構，讓用戶能在短時間內上手與應用。',
    SummarySection_secBlockConfig_items_2_title: '突破視覺框架',
    SummarySection_secBlockConfig_items_2_description:
      '普遍醫護印象都較為專業沈穩，此次將代表穩重的藍色融合趣味的元素與角色，突破醫護產品的框架，創造專屬風格定位，即時吸引用戶的第一印象。',
    SummarySection_secBlockConfig_items_3_title: '建立學習捷徑',
    SummarySection_secBlockConfig_items_3_description:
      '為求更有效率的學習管道，將各單元新增收藏功能，輔助用戶更即時的獲取知識，提升學習的效益。',
    feature_highlight: '功能亮點',
    FrameA_title_1: 'Docs4Docs',
    FrameA_description_1:
      'Docs4Docs是第一款專為非英語人士設計，能學習醫療、醫護英文的用戶友善應用程式。Docs4Docs為非英語人士提供一套學習與獲取醫護英文的全新模式。嘗試看看我們所打造的MediDocs、MediLinks、MediShare、MediTerms、MediFavorites一起探索醫學世界吧！',
    FrameA_title_2: 'MediDocs',
    FrameA_description_2:
      '在MediDocs每個單元中，你可以從富有內涵的文章內容中學到新的醫護術語和常用的醫療單字、片語與句子。透過單元測驗考試可以檢視本身的理解能力。補充教材能經過模組學習，有效幫助擴展你的詞彙量。',
    FrameB_title_1: 'MediLinks',
    FrameB_description_1:
      '你可以透過透過MediLinks發現許多有用的醫學新聞、研究、書籍、關於各種醫護主題的影片、臨床技術影片、臨床案例、測驗。',
    FrameA_title_3: 'MediShare',
    FrameA_description_3:
      'MediShare是由英文為母語的醫療專業人士所分享，主題分享關於健康、醫療等。',
    FrameB_title_2: 'MediTerms',
    FrameB_description_2: '透過最有用的網站來搜尋醫學術語與定義的數據庫。',
    FrameB_title_3: 'MediFavorites',
    FrameB_description_3:
      '選擇你最喜歡的單元讓他們成為你的最愛，創造能複習所有重要醫療內容的方式。',
  },
  systex: {
    BannerDefault_subTitle: '精誠資訊',
    BannerDefault_title: '精誠電商整合網站',
    BannerDefault_description: 'All-in-One 全方位電商服務平台',
    SummarySection_subTitle: '精誠電商整合網站',
    SummarySection_title: '迎向大電商時代，打造數位體驗新商機',
    SummarySection_description:
      '已在資訊產業服務多年的精誠團隊，以嶄新的視覺樣貌，結合長期深耕電商與代營運的實務經驗，針對中小型電商推出all-in-one全方位整合平台，帶給使用者更智能便利的數位體驗。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '中小型品牌電商',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#電商服務',
    SummarySection_secBlockConfig_items_1_title: '重新制定視覺風格',
    SummarySection_secBlockConfig_items_1_description:
      '重新定義網站的主視覺，並衍伸視覺風格一致的圖像、圖標等',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '將服務內容整理潤飾並重新調整位置順序，幫助使用者在閱讀時更舒適流暢',
    SummarySection_secBlockConfig_items_3_title: '資訊圖像化',
    SummarySection_secBlockConfig_items_3_description:
      '精簡較為艱澀的專業術語與服務流程，創造親和力較高的視覺語言',
    design_highlight: '設計亮點',
    feature_highlight: '功能亮點',
    FrameB_title_1: '嶄新的視覺形象',
    FrameB_description_1:
      '以高彩度的藍為主色彩，搭配鮮明的黃與純黑線條，塑造有別與以往的視覺樣貌。',
    FrameA_title_1: '一致化的視覺語言',
    FrameA_description_1:
      '將服務內容與特色轉化為風格一致的視覺圖像，除了維持網站與品牌的視覺調性，也加深使用者對品牌與其服務的印象連結。',
    FrameD_title_1: '服務資訊的整合',
    FrameD_description_1:
      '對於客戶提供的多項服務與產品，將其一一制定了對應的視覺元素，幫助使用者在一進入網站時，能快速瀏覽所有的項目，並找到符合自身業務需求的服務。',
    FrameA_title_2: '簡易明瞭的資訊圖表',
    FrameA_description_2:
      '考量到使用者過去可能尚未使用過相關服務，我們將較複雜的資訊消化整理，並以簡潔的圖表方式呈現，幫助使用者更舒適地吸收資訊。',
  },
  PawboLife: {
    BannerDefault_subTitle: 'PAWBO波寶創新科技（宏碁集團）',
    BannerDefault_title: 'Pawbo Life',
    BannerDefault_description: 'IoT寵物智能互動APP',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `Pawbo Life 讓您隨時隨地在遠端與毛孩互動，除了支援多國語系（中文、英文、法文、日文），針對個別硬體裝置的連線規格與特性，也規劃出順暢的配對與操作流程，讓使用者能輕鬆使用。由於各硬體裝置提供的資訊不同，在資訊呈現與訊息傳遞上，特別設計符合用戶體驗的介面與閱讀方式。

    支援平台：iOS / Android`,
    SummarySection_subTitle: 'Pawbo Life',
    SummarySection_title: '毛孩與主人的互動樂園',
    SummarySection_description:
      'Pawbo Life是一款寵物智能APP，只要與Crunchy寵物餵食機、Pawbo+寵物互動攝像機、iPuppyGo寵物智慧扣配對後，就能在遠端與毛孩互動與餵食；同時也能紀錄毛孩每天的運動量、睡眠時間、心情指數，隨時掌握寵物的健康狀態。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '寵物飼主',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#寵物IoT',
    SummarySection_secBlockConfig_items_1_title: '全新定義',
    SummarySection_secBlockConfig_items_1_description:
      '參照品牌風格及產業設計趨勢，定義出符合該產品的介面元件、色彩應用以及產品LOGO，並延伸至介面中。',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '優化既有產品介面及定義、規劃新功能頁面，確保產品體驗與視覺能夠保持操作上的一致性。',
    SummarySection_secBlockConfig_items_3_title: '功能模組化擴充',
    SummarySection_secBlockConfig_items_3_description:
      '因應產品功能與裝置的擴充，規劃出有彈性的資訊架構，讓功能物件以模組化的形式呈現，以便未來擴充。',
    feature_highlight: '功能亮點',
    FrameA_title_1: '一次連接多台裝置',
    FrameA_description_1:
      '可同時配對多台Crunchy寵物餵食機、Pawbo+寵物互動攝像機、iPuppyGo寵物智慧扣，無論使用者在家或在外，都能透過APP與毛孩互動。',
    FrameA_title_2: '即時影像與雙向語音',
    FrameA_description_2:
      '透過智慧移動偵測，使用者可以設定偵測區域範圍，觸發即時錄影，即便外出也能追蹤毛孩在屋內的動態。',
    FrameB_title_1: '智慧自動餵食',
    FrameB_description_1:
      '使用APP控制Crunchy寵物餵食機，即可遠端設定餵食，隨時關注毛孩的飲食數據。依照寵物平時的飲食習慣，可以手動選擇定時定量餵食或時間排程餵食。',
    FrameC_title_1: '智慧記錄毛孩活動',
    FrameC_description_1:
      '透過iPuppyGo寵物智慧扣，智慧紀錄日常的活動量、睡眠時間、心情指數，讓使用者能全方位掌握毛孩的健康狀況。',
    FrameA_title_3: '智慧移動偵測',
    FrameA_description_3:
      '透過智慧移動偵測，使用者可以設定偵測區域範圍，觸發即時錄影，即便外出也能追蹤毛孩在屋內的動態。',
    FrameA_title_4: '事件影像紀錄',
    FrameA_description_4:
      '事件影像紀錄功能，讓使用者不管身處家中或戶外，都能隨時瀏覽過去發生的大小事，無論是寵物或人物觸發的事件，都會被紀錄保留。',
  },
  Swiper: {
    BannerDefault_subTitle: '串點移動科技',
    BannerDefault_title: 'Swiper 滑吧',
    BannerDefault_description: '探索地圖到處都有驚喜',
    SummarySection_subTitle: 'Swiper 滑吧',
    SummarySection_title: '最創新的地圖社群APP',
    SummarySection_description:
      '透過地圖社群與滑地標累積點數方式，免費搜集X幣點數。只要開啟Swiper滑吧地圖，就能探索所在地區最新的大小事，同時也能使用搜集的X幣點數兌換商城中的各種優惠票券與專屬抽獎活動。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '一般用戶',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#社群平台',
    SummarySection_secBlockConfig_items_1_title: '全新介面大改版',
    SummarySection_secBlockConfig_items_1_description:
      '重新定義APP的介面風格與色彩應用，並將設計元件標準化，讓整體更一致',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '將產品功能操作流程整理優化並重新調整操作行為與互動體驗，幫助使用者在瀏覽時更舒適流暢',
    SummarySection_secBlockConfig_items_3_title: '跨平台應用',
    SummarySection_secBlockConfig_items_3_description:
      '將新功能整合至現有功能中，同時能合理地將其部署至對應的項目群組，讓用戶能更直覺順暢的探索新功能',
    feature_highlight: '功能亮點',
    FrameB_title_1: '滑地圖收集點數',
    FrameB_description_1:
      '於使用者的所在位置，只要踩點地圖上的地標，就可以免費搜集X幣點數與經驗值，並透過票券商城兌換各種優惠好禮。',
    FrameA_title_1: '注重隱私的匿名機制',
    FrameA_description_1:
      '透過發文身份切換功能，使用者可以自由選擇不同角色身份發文。不好意思說出的話，就選用匿名發佈！',
    FrameA_title_2: '在地社群',
    FrameA_description_2:
      '透過附近貼文功能，立即瀏覽所在地區周遭最新的大小事。使用者也可以建立貼文，與Swiper的用戶分享當下心情、優惠資訊等。',
    FrameA_title_3: '票券商城',
    FrameA_description_3:
      '使用者可以透過滑地標累積的X幣點數，在票券商城中兌換各種優惠票券，或是參與專屬抽獎活動。',
  },
  HiStock: {
    BannerDefault_subTitle: '能方科技股份有限公司 ',
    BannerDefault_title: 'HiStock 嗨投資',
    BannerDefault_description: '理財好幫手，給您最即時完整的財經資訊',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `HiStock嗨投資，是幾位在園區工作10年以上且對投資有興趣的夥伴，與熱愛投資並樂於分享自身看法與經驗的理財達人，一起共同創建的網路學習與分享環境！

支援平台：Web / iOS / Android`,
    SummarySection_subTitle: 'HiStock嗨投資',
    SummarySection_title: '理財好幫手，給您最即時完整的財經資訊',
    SummarySection_description:
      'HiStock嗨投資是一款投資理財APP，為了提供用戶更完善的體驗，特別將網頁版功能移植至手機版本。 為使用者打造專屬投資討論區功能，隨時與達人和同好交流！並提供追蹤喜愛專欄與講師等功能，獲取最新課程內容，隨時隨地學投資。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '一般用戶',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#投資理財',
    SummarySection_secBlockConfig_items_1_title: '完善用戶體驗',
    SummarySection_secBlockConfig_items_1_description:
      '依據手機用戶的使用體驗，完善規劃出符合APP端操作的資訊架構佈局，讓用戶無論在網頁版或手機版皆能快速與順暢地瀏覽操作。',
    SummarySection_secBlockConfig_items_2_title: '網頁版功能移植',
    SummarySection_secBlockConfig_items_2_description:
      '透過資訊架構彙整，將網頁版指定功能理財學院、嗨訂閱、站內信、我的課程、站內信、商城、課程會期等項目，完整移植至APP。',
    SummarySection_secBlockConfig_items_3_title: '新功能整合',
    SummarySection_secBlockConfig_items_3_description:
      '因應客戶需求，提供高度客製化聊天室功能，導入表情符號、圖片上傳、管理員權限管理、記事本等功能，以便讓老師們即時與學員互動。',
    feature_highlight: '功能亮點',
    FrameB_title_1: '理財學院',
    FrameB_description_1:
      '提供全年度完整學習方案（包含老師使用的看盤工具與系統、全套影音課程、每日教學討論群組），適合有時間鑽研的投資人學習。',
    FrameA_title_1: '嗨訂閱',
    FrameA_description_1:
      '習慣線上學習的族群，或是希望定期追蹤特定講師的最新內容者，可以自由訂閱追蹤專欄的內容與商品。',
    FrameA_title_2: '我的課程',
    FrameA_description_2:
      '可瀏覽使用者所購買的理財學院或訂閱專欄，觀看其提供之文章、影片、直播課程等服務內容。',
    FrameB_title_2: '會員討論區',
    FrameB_description_2:
      '部分理財學院、嗨訂閱、主題影音課程，有隨之附贈課後會員討論區，使用者可在此與達人或同學互相交流學習心得。',
    FrameA_title_3: '商城',
    FrameA_description_3:
      '顯示熱門理財學院、嗨訂閱、理財課程的優惠與活動購買連結，方便使用者快速瀏覽人氣項目。',
  },
  EmploymentGoldCard: {
    BannerDefault_subTitle: '國家發展委員會',
    BannerDefault_title: '就業金卡會商系統',
    BannerDefault_description: '外國專業人才審核平台',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `因應全球疫情，以便利外國的優秀專業人才來台灣工作，由移民署受理後轉交給各部會審查的線上簽核系統。

      支援平台：Web`,
    SummarySection_subTitle: '會商系統',
    SummarySection_title: '外國專業人才，就業金卡會商系統',
    SummarySection_description:
      '透過客製化的流程設計，規劃出符合各單位使用角色受理案件時，所需要相關資訊呈現，無論是承辦中案件、代辦中案件、未半結案件、已結案案件，都能透過果我們精心的流程與介面設計規劃，以最有效率的方式完成案件審查。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '就業金卡相關單位',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#簽核系統',
    SummarySection_secBlockConfig_items_1_title: '用戶體驗',
    SummarySection_secBlockConfig_items_1_description:
      '針對各部會承辦人員會遇到的使用情境，提供對應的解決方案。在流程作業上能更有效率完成目標。',
    SummarySection_secBlockConfig_items_2_title: '資訊架構彙整',
    SummarySection_secBlockConfig_items_2_description:
      '申請案件在不同審核階段，都會由對應的人員負責，每個審核環節所需的資訊架構也有所不同。透過清晰的邏輯流程分析與彙整，讓每個角色能夠順暢地執行任務。',
    SummarySection_secBlockConfig_items_3_title: '客製化群組資訊',
    SummarySection_secBlockConfig_items_3_description:
      '由於各部會單位負責環節需要的表單內容不同，在資訊呈現部分會根據使用情境採用不同的表單模組，例如：清單式選項表單、受理意見表單、分選形式標籤選單等。',
    feature_highlight: '功能亮點',
    FrameA_title_1: '作業一覽表',
    FrameA_description_1:
      '透過一覽表，無論是會商作業還是部會案件，都能一覽無遺，快速了解每個案件的狀態。',
    FrameA_title_2: '承辦中案件',
    FrameA_description_2:
      '所屬承辦人員的案件清單，透過巧思的設計，規劃出符合承辦人員作業流程的操作體驗，讓作業流程更順暢、編輯案件更有效率。',
    FrameA_title_3: '代理人設定',
    FrameA_description_3:
      '請假人員可透過代理人設定功能指派代理人，讓案件能夠如期進行。',
    FrameB_title_1: '帳號管理',
    FrameB_description_1:
      '輕鬆管理跨部門承辦人員帳號與管理權限。透過簡易的新增設定，承辦人員的基本資料編輯、所屬部門與單位，皆可分組管理。',
  },
  UREBESS: {
    BannerDefault_subTitle: 'URE 聯合再生能源',
    BannerDefault_title: 'URE BESS',
    BannerDefault_description: '家用儲能系統APP',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `在大缺電的危機下，無論是大型企業、中小型企業與家庭用戶，逐年漸漸對儲能的概念有提升的趨勢。URE聯合再生能源針對不同階層的目標用戶提供完善的儲能系統解決方案。此次的合作，主要針對家用型儲能系統，客製化專屬監控APP UI/UX設計，同時支援雙系統平台之手機與平板電腦。 支援多國語系: 中文、英文、德文、日文。

      支援平台：iOS / Android`,
    SummarySection_subTitle: 'URE BESS',
    SummarySection_title: '打造自給自足的電力環境',
    SummarySection_description:
      'URE BESS 是一款家用儲能系統智慧監控APP，隨時監控與優化24/7的能源。藉由功率功能、統計數據圖表、儲能系統即時監控功能，讓使用者全面掌握家中用電與儲電狀況，改善用電習慣和節省資金。在產品設計面上，特別針對居家用戶，包括男性與女性，客製專屬情境主題。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '居家用戶',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#IoT智慧能源',
    SummarySection_secBlockConfig_items_1_title: '視覺風格制定',
    SummarySection_secBlockConfig_items_1_description:
      '參照企業產品應用規範，為APP設計符合該產品的介面元件、色彩應用，並應用於各裝置平台。',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '簡單直覺的介面設計，針對資訊傳遞目標，客製化專屬圖表與儲能系統資訊狀態呈現介面。',
    SummarySection_secBlockConfig_items_3_title: '跨平台應用',
    SummarySection_secBlockConfig_items_3_description:
      '支援iOS、Android跨平台使用，設計出符合對應系統之使用設計規範，並支援手機與平板電腦操作。',
    feature_highlight: '功能亮點',
    FrameA_title_1: '智能裝置監控管理',
    FrameA_description_1:
      '透過APP隨時遠端監控電池狀況，可以在白天儲存太陽能系統產生的電力，提供居家夜間用電。輕鬆分配用電時段，節省用電量與費用。',
    FrameB_title_1: '功率監控',
    FrameB_description_1:
      '無論使用者身在何處，都能掌握設置在家中的儲能系統不同時段的功率狀況，包括太陽能電力使用狀況、系統儲存電量狀況、買電、用電以及賣電狀態，皆可一目瞭然。',
    FrameA_title_2: '收益管理',
    FrameA_description_2: '透過APP管理使用者的賣電、自用收益、谷充峰用狀況。',
    FrameA_title_3: '統計管理',
    FrameA_description_3:
      '即時掌握儲能系統之歷史發電、買電、用電、賣電、光能自用和儲能自足的使用情況。',
  },
  Focoten: {
    BannerDefault_subTitle: 'Orbweb Inc.',
    BannerDefault_title: 'Focoten',
    BannerDefault_description: '雲端智能視訊監控平台',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `Focoten是一套改善傳統視訊監控系統平台。利用最新的雲端技術、AI人工智慧、電腦視覺與影像處理，來改善目前傳統監控平台的安全性、隱私性與和用戶體驗。

      支援平台：Web / iOS / Android `,
    SummarySection_subTitle: 'Focoten',
    SummarySection_title: '現代企業智能視訊監控系統平台',
    SummarySection_description:
      '使用Focoten智能監控系統平台，使用者可以透過監控攝影機偵測可疑的人或移動物體，系統會立刻註記為發生事件，捕捉到的事件影像會透過影像串流至雲端儲存；用戶也能透過AI智慧辨識功能，選定某區域的人或車輛進行特徵過濾。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '企業用戶',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#IoT監控應用',
    SummarySection_secBlockConfig_items_1_title: '重新制定視覺風格',
    SummarySection_secBlockConfig_items_1_description:
      '參照企業風格及產業設計趨勢，定義出符合該產品的介面元件、色彩應用以及產品LOGO，並應用於在不同裝置上',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '完善規劃個別功能在不同平台的操作體驗。因IoT產業著重於安全性與隱私性，在產品中特別強化雙向身份認證流程。',
    SummarySection_secBlockConfig_items_3_title: '跨平台應用',
    SummarySection_secBlockConfig_items_3_description:
      '支援Web、iOS、Android跨平台使用，將網頁版的功能移植至行動APP，設計出符合對應系統之使用設計規範。',
    feature_highlight: '功能亮點',
    FrameA_title_1: '即時雲端監控',
    FrameA_description_1:
      'Focoten提供24/7全天候雲端監控平台，讓使用者能輕鬆且隨時隨地觀看即時影像與錄影儲存影像。',
    FrameC_title_1: '人物與車輛過濾',
    FrameC_description_1:
      '使用者可以透過人物過濾與車輛過濾功能，設定個別的過濾條件，例如：指定衣物與車輛的顏色、人物是否攜帶配件、車輛類型等（汽車、腳踏車、摩托車、公車、卡車），來更輕鬆地找尋重要影像畫面。',
    FrameA_title_2: '支援多路畫面',
    FrameA_description_2:
      '透過多路影像觀看功能，可以讓使用者一次瀏覽不同地區安裝的即時攝影機畫面。',
    FrameB_title_1: '分享與下載',
    FrameB_description_1:
      '傳送連結給您的家人、同事，與他們分享實時影像，同時能設定攝影機即時影像瀏覽時間。 另外支援下載雲端錄影至本地端（手機、電腦），讓使用者在無網路狀態時也可以輕鬆觀看。',
    FrameC_title_2: '監控儀表板',
    FrameC_description_2:
      '透過監控儀表板，輕鬆關注裝置使用情況。攝影機事件狀態、攝影機裝置上線與離線狀態、網路使用狀態，讓使用者一目瞭然。',
  },
  ZhihanIVISystem: {
    BannerDefault_subTitle: '智瀚科技 Advanced & Wise Technology Corp',
    BannerDefault_title: 'In-Vehicle Infotainment System',
    BannerDefault_description: '車載應用平台系統',
    SummarySection_title: '專案介紹',
    SummarySection_description:
      '汽車人機交互介面是駕駛與汽車的溝通橋樑，不論是軟體介面的設計，也包含硬體控制，如何連結與提升人與汽車的體驗品質是相當重要的環節。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'HMI GUI',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '汽車駕駛',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#車載應用',
    SummarySection_secBlockConfig_items_1_title: '資訊聚焦',
    SummarySection_secBlockConfig_items_1_description:
      '為求用戶更優化的操作體驗，必須確保重點資訊是清楚醒目的，讓用戶能迅速捕捉信息，在任何環境下都擁有最佳的易讀性與可視性，才能讓駕駛人士專心且安全的行駛。',
    SummarySection_secBlockConfig_items_2_title: '高效反饋',
    SummarySection_secBlockConfig_items_2_description:
      '在多種情境下需考量到用戶讀取資訊的效率，因此除符合基礎視覺顯示規範外，也須重視與安全提醒、警告通知、文字顯示等問題，讓用戶得到最佳的視覺反饋。',
    SummarySection_secBlockConfig_items_3_title: '視覺定位',
    SummarySection_secBlockConfig_items_3_description:
      '在一眾簡約現代的風格趨勢下，融合銀白金屬質感點綴，增加一絲復古的精緻元素，有別於市面上常見的調性，更能豐富視覺層次。',
    FrameA_subTitleConfig_1_text: 'Inspiration',
    FrameA_title_1: '設計發想',
    FrameA_description_1:
      '融合復古與現代的視覺調性，調和出低調內斂與輕奢感之間的平衡點，重新詮釋復古的定義。',
    FrameA_subTitleConfig_2_text: '設計亮點',
    FrameA_title_2: '中控首頁',
    FrameA_description_2:
      '消光質感的金屬紋理，融入藍色的跳色元素，凸顯整體視覺亮點與層次感。框架以漸消形式呈現隱約的邊界，清晰又不搶眼的區隔選項。',
    FrameA_title_3: '裝置串連',
    FrameA_description_3:
      '考量到多種裝置連結的情境，因此運用主副色區分狀態，讓用戶能夠迅速判斷該裝置的串連狀況。',
  },
  ZhihanDigitalInstrumentCluster: {
    BannerDefault_subTitle: '智瀚科技 Advanced & Wise Technology Corp',
    BannerDefault_title: 'Digital Instrument Cluster',
    BannerDefault_description: '車載應用平台系統',
    SummarySection_title: '專案介紹',
    SummarySection_description:
      '機車與汽車的人機交互體驗有些許差異性，機車使用情境大多以硬體控件操作為主，因此需考量到如何讓駕駛在最安全的情況下執行功能設定，整合軟體與硬體間的使用思維，打造良好的使用體驗。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'HMI GUI',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '機車騎士',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#車載應用',
    SummarySection_secBlockConfig_items_1_title: '資訊聚焦',
    SummarySection_secBlockConfig_items_1_description:
      '為求用戶更優化的操作體驗，必須確保重點資訊是清楚醒目的，讓用戶能迅速捕捉信息，在任何環境下都擁有最佳的易讀性與可視性，才能讓駕駛人士專心且安全的行駛。',
    SummarySection_secBlockConfig_items_2_title: '高效反饋',
    SummarySection_secBlockConfig_items_2_description:
      '在多種情境下需考量到用戶讀取資訊的效率，因此除符合基礎視覺顯示規範外，也須重視與安全提醒、警告通知、文字顯示等問題，讓用戶得到最佳的視覺反饋。',
    SummarySection_secBlockConfig_items_3_title: '整合體驗',
    SummarySection_secBlockConfig_items_3_description:
      '考量全硬體操作數位介面，將多種狀態利用顏色等形式做出明顯的差異性，讓用戶能感受到操作體驗上的同步感。',
    FrameA_subTitleConfig_1_text: 'Inspiration',
    FrameA_title_1: '設計發想',
    FrameA_description_1:
      '以極簡俐落的元素表達速度感的視覺印象，金屬質感與切割的點綴提升視覺豐富性與亮點。',
    FrameA_subTitleConfig_2_text: '設計亮點',
    FrameA_title_2: '油車&電車儀表',
    FrameA_description_2:
      '因應油車與電動機車的儀表資訊顯示不同，整合出兩種機車都能使用的模組設計，並以極簡的扇形格狀呈現儀表，在轉速過高或油電量過低的狀態會亮起紅色區塊的警告提醒。',
    FrameA_title_3: '模組化設計',
    FrameA_description_3:
      '因應多元情境模擬，除基本的里程資訊顯示外，將影音媒體、來電通知、通話中等功能整併於首頁畫面，讓用戶能快速獲得資訊，並且安全的行駛。',
  },
  F10: {
    BannerDefault_subTitle: 'Orbweb Taiwan Inc.',
    BannerDefault_title: 'F10',
    BannerDefault_description: '線上雲端監控平台',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `安全攝像頭越來越多地用於在我們的社會中提供安全和視覺感知。據估計，當今全球安裝了約 7.7 億個安全攝像頭，而且這個數字還在迅速增加，到 2022 年將超過 10 億個。不管你喜不喜歡，它們正走進我們生活的每一個角落。F10 是我們改進傳統安全攝像系統並徹底改變視頻安全系統未來的努力。

    支援平台：Web`,
    SummarySection_subTitle: 'F10',
    SummarySection_title: '現代企業智能監控平台',
    SummarySection_description:
      'F10 建立在最新的雲端計算技術上，提供高經濟效益、可擴展的即時視訊監控解決方案，幫助不同領域的企業。',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '企業用戶',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#IoT監控平台',
    SummarySection_secBlockConfig_items_1_title: '視覺風格延伸',
    SummarySection_secBlockConfig_items_1_description:
      '參照品牌風格及產業設計趨勢，定義出符合該產品的介面元件、色彩應用以及產品LOGO，並延伸至介面中。',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '優化既有產品介面及定義、規劃新功能頁面，確保產品體驗與視覺能夠保持操作上的一致性。',
    SummarySection_secBlockConfig_items_3_title: '功能模組化擴充',
    SummarySection_secBlockConfig_items_3_description:
      '因應產品功能與裝置的擴充，規劃出有彈性的資訊架構，讓功能物件以模組化的形式呈現，以便未來擴充。',
    feature_highlight: '功能亮點',
    FrameB_title_1: '產品首頁',
    FrameB_description_1:
      '針對產品功能優勢介紹，規劃與之對應的情境畫面，直覺地傳遞訊息。整體設計以簡潔、乾淨俐落的風格處理。',
    FrameA_title_1: '即時監控管理',
    FrameA_description_1:
      'F10提供高度彈性的裝置擴充，無論是攝像機數量、用戶人數都沒有限制。只要連接F10機盒，無論安裝在室內或室外甚至沒有網路的地方都能使用，讓使用者方便管理監控攝像機。',
    FrameC_title_1: '歷史紀錄',
    FrameC_description_1:
      '透過歷史紀錄功能，無論是人物事件觸發、車輛事件觸發或槍聲事件觸發，都能全天候24/7記錄。',
    FrameA_title_2: '用戶管理',
    FrameA_description_2:
      '提供使用者新增用戶、移除用戶、編輯用戶名稱、設定角色權限等管理功能。',
    FrameA_title_3: '硬體裝置管理',
    FrameA_description_3:
      '即時取得裝置上線、離線狀態等資訊，便捷地管理您的F10機盒與攝像機。',
    FrameC_title_2: '智能監控儀表板',
    FrameC_description_2:
      '使用者可隨時瀏覽平台的使用狀態，無論是事件發生紀錄、攝像機健康狀態與網路流量的使用情形。',
  },
  ConneXionONE: {
    BannerDefault_subTitle: 'Connection Portal Inc.',
    BannerDefault_title: 'ConneXionONE.live',
    BannerDefault_description: '適用於企業與教育機構的視訊會議服務',
    ProjectIntroduction_title: '專案介紹',
    ProjectIntroduction_description: `在Covid-19肆虐下，全球嚴峻的疫情迫使人們必須在家遠端工作與學習。Connection Portal Inc.從中看見新商機，結合市面上產品(e.g. Google Meet, Zoom, Cisco Webex, Microsoft teams)不同的商業模式促成了此次合作，由日創團隊負責產品規劃與設計。

    支援平台：Web`,
    SummarySection_subTitle: 'ConneXionOne',
    SummarySection_title: '遠距工作與線上教學的好幫手',
    SummarySection_description:
      'ConneXionONE.live 是一款集結了線上會議與線上教學所需功能為一身的產品，主要功能包含: 線上即時聊天、多方視訊會議、線上白板協作、會議與課程錄影、記事本、會議投票與問答活動、分組討論室。在疫情肆虐的環境，ConneXionONE.live 會是你遠距工作與線上教學的好幫手！',
    SummarySection_tagConfigs_1_title: '服務類型',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: '目標用戶',
    SummarySection_tagConfigs_2_text: '遠距工作/教學',
    SummarySection_tagConfigs_3_title: '應用領域',
    SummarySection_tagConfigs_3_text: '#視訊會議',
    SummarySection_secBlockConfig_items_1_title: '全新定義',
    SummarySection_secBlockConfig_items_1_description:
      '依據公司品牌主視覺延伸至網頁配色，營造出沈穩與企業嚴謹感。整體設計上使用較多的圓角，讓產品感覺比較容易親近，降低用戶的視覺疲勞',
    SummarySection_secBlockConfig_items_2_title: '用戶體驗優化',
    SummarySection_secBlockConfig_items_2_description:
      '將常用的主要功能做整合、群組化，讓整體產品體驗更能簡單、直覺好理解。透過相關產品分析後的整體規劃，讓用戶使用上能降低學習曲線好上手。',
    SummarySection_secBlockConfig_items_3_title: '支援RWD網頁設計',
    SummarySection_secBlockConfig_items_3_description:
      '支援跨裝置操作，無論你在何處，只要透過電腦、平板或手機都能快速使用。因應不同裝置定義出友善直覺的操作介面與體驗。',
    feature_highlight: '功能亮點',
    FrameA_title_1: '線上白板協作',
    FrameA_description_1:
      '使用者可和與會者用線上白板一起協作流程圖、草圖繪製。學生或同事能輕鬆地共同創作內容而且支援下載保存，方便線上會議、課程後檢視整理。',
    FrameA_title_2: '記事本分享',
    FrameA_description_2:
      '使用者可和與會者一起協作筆記。線上會議、課程結束後透過輸出功能將記事本內容以不同檔案格式下載並保存，例如：PDF, Microsoft Word, HTML等。',
    FrameB_title_1: '會議投票與問答活動',
    FrameB_description_1:
      '透過簡易的線上投票及問答功能，搜集同事、學生的意見，鼓勵大家參與線上互動表達自己的想法。',
    FrameA_title_3: '分享外部鏈結影片',
    FrameA_description_3:
      '支援播放Youtube影片功能，會議討論、線上課程的過程中輕鬆和與會者分享影片內容。',
    FrameB_title_2: '會議分組討論室',
    FrameB_description_2:
      '在線上會議過程中建立分組討論室，會議主持人可設定討論室的時間、人數以及將人員指派到討論室。分組討論室時間結束後，小組成員會自動回到主要的會議室中。',
  },
}
