export default {
  home: {
    services: 'Services',
    services_subtitle: 'Transforming ideas into exceptional user experiences',
    portfoilo_works: 'Portfolio',
    about_dailycreative: 'About Us',
    contact_us: 'Contact',
    hero_1: 'Intuitive Product Design',
    hero_2: 'Creating seamless user experiences',
    hero_sub_1: 'We focus on the details that matter,',
    hero_sub_2: 'delivering designs that truly connect users with their needs',
    ux: 'UX Design',
    ux_1: '- Needs Analysis',
    ux_2: '- User Research',
    ux_3: '- User Flow Mapping',
    ui: 'UI Design',
    ui_1: '- Visual Styling',
    ui_2: '- Interface Design',
    hmi: 'HMI Design',
    hmi_1: '- Hardware-Software Integration',
    hmi_2: '- Information Architecture',
    app_web_programming: 'App & Web Development',
    app_web_programming_1: '- Full-Stack Development',
    app_web_programming_2: '- Database Integration',
    portfoilo_works_list: 'Featured Work',
    portfoilo_works_subtitle: 'Crafting connections through thoughtful design',
    portfoilo_works_learn_more: 'Learn More',
    docs4docs_the_englis_name: 'Docs4Docs',
    docs4docs_the_englis_title: 'English Learning for Healthcare Professionals',
    docs4docs_the_englis_description:
      'An integrated platform combining medical education and English language resources, designed to support the growth of clinical and R&D professionals.',
    systex_ecommerce_int_name: 'SYSTEX eCommerce Platform',
    systex_ecommerce_int_title: 'All-in-One eCommerce Solution',
    systex_ecommerce_int_description:
      "SYSTEX's comprehensive eCommerce platform offers small and medium-sized businesses a user-friendly digital experience, backed by years of industry expertise.",
    pawbo_life_the_inter_name: 'Pawbo Life',
    pawbo_life_the_inter_title: 'Interactive Pet Care Platform',
    pawbo_life_the_inter_description:
      "Pawbo Life is a smart pet app that enables real-time interaction and monitoring of your pets, integrating with Pawbo's range of pet care devices.",
    readmore: 'Read More',
    testimonial_1_title: 'ProudFit Technology / YC Huang',
    testimonial_1_texts_1:
      'The DailyCreative team delivered not just a great tech experience, but a truly collaborative partnership. Their professionalism, attentive communication, and satisfactory design made the entire process smooth and successful.',
    testimonial_2_title:
      'National Kaohsiung University of Science and Technology / Jo Shan Fu',
    testimonial_2_texts_1:
      'Professional service, customized solutions, high-efficiency execution',
    testimonial_2_texts_2:
      "The team's diverse expertise and precise problem-solving skills efficiently address client needs. Highly recommended!",
    testimonial_3_title: 'HiStock / GM Cheng',
    testimonial_3_texts_1:
      'DailyCreative is a customer-focused development team with a shared industry background. Their support streamlined our product development, leading us to continue our collaboration. For similar development needs, you can trust DailyCreative to deliver.',
    testimonial_4_title: 'Pawbo Inc. / Vock Zheng',
    testimonial_4_texts_1:
      'Professional project implementation with clear, prompt feedback. The team quickly internalizes customer needs and provides accurate, expert solutions. A truly exceptional team.',
    testimonial_5_title: 'HENGE TECHNOLOGY CO., LTD. / Nick',
    testimonial_5_texts_1:
      'The DailyCreative team takes the time to understand original needs, maintains excellent communication throughout the design process, and produces results that exceed expectations.',
    testimonial_6_title: 'Advanced & Wise Technology Corp. / Ting Huang',
    testimonial_6_texts_1:
      'Thanks to Andy and his team for their attentive attitude, patient communication, professional recommendations, and comprehensive services! We look forward to continuing our collaboration.',
    testimonial_7_title: 'Pawbo Inc. / Danny Lee',
    testimonial_7_texts_1:
      'The experienced design team clearly understands product functions and user-centric design concepts, effectively addressing user issues. Overall, a pleasant collaboration that we look forward to continuing in the future.',
    testimonial_8_title: 'Garmin / Carol Kao',
    testimonial_8_texts_1: 'Professional, Proactive, Quality, and Efficient',
    testimonial_8_texts_2:
      'The team listens attentively to customer needs, generating constructive ideas through professional input and experience exchanges. Their efficient project implementation delivers impressive, quality outcomes. A thoroughly enjoyable collaborative process. This team is highly recommended.',
    collaborative_title: 'Our Partners',
    collaborative_subtitle:
      'Together, we embrace challenges and drive innovation',
    contact_us_title: 'Ready to chat with DailyCreative?',
    contact_us_subtitle_1:
      "Let's bring your ideas to life and create better user experiences!",
    contact_us_subtitle_2: '',
  },
  header: {
    services: 'Services',
    our_services: 'Our Services',
    project_flow: 'Project Flow',
    works: 'Portfolio',
    about_us: 'About Us',
    contact_us: 'Contact',
    facebook: 'Facebook',
  },
  about_us: {
    hero: 'About Us',
    hero_text: 'Creating meaningful connections in every user interaction.',
    core_values: 'Our Values',
    core_values_title_1: 'Crafting Connective Products',
    core_values_title_2: 'Delivering Intuitive User Experiences',
    core_values_text_1:
      'Collaborating closely with clients to develop world-class products that drive commercial value.',
    core_values_text_2:
      'At DailyCreative, we believe in the power of everyday inspiration.',
    core_values_text_3:
      'We use UX/UI design and development to bring creative ideas to life.',
    founding_tale_title: 'Our Story',
    founding_tale_text_1:
      'Founded in 2019, DailyCreative specializes in app, HMI, and responsive web design. We help clients create innovative service models and maximize user engagement.',
    founding_tale_text_2:
      "Our expert team, backed by over a decade of tech industry experience, offers end-to-end custom solutions. From initial product planning to ongoing maintenance and upgrades, we cater to our clients' unique needs in the mobile space.",
    team_background_title: 'Our Expertise',
    team_background_text:
      'With over 10 years in high-tech and IoT integration, we blend practical service experiences with cutting-edge hardware to create seamless product experiences.',
    tailor_made_services_title: 'Custom Solutions',
    tailor_made_services_text:
      "We offer bespoke services tailored to each client's needs, covering everything from initial product planning to long-term maintenance for web and mobile platforms.",
    commercial_values_title: 'Business Impact',
    commercial_values_text:
      'We boost user engagement and brand loyalty, creating sustainable value while guiding clients through dynamic industry challenges.',
    strategic_partnership_title: 'Strategic Partnerships',
    strategic_partnership_text:
      'We collaborate with industry leaders to bring innovative designs to life and achieve ambitious product goals.',
  },
  contact_us: {
    contact_us_title: 'Contact Us',
    contact_us_text:
      "Let's fulfill our common goals by realizing your dreams of advancing users' experiences for the better!",
    contact_information: 'Contact Information',
    contact_window: 'Name',
    contact_window_placeholder: 'Please input your name',
    contact_phone: 'Phone',
    contact_phone_placeholder: 'Please input the phone number',
    contact_email: 'Email',
    contact_email_placeholder: 'Please input Email address',
    contact_email_error: '⚠ Please fill in a valid email address',
    contact_company: 'Company',
    contact_company_placeholder: 'Please input company name',
    contact_project_demand: 'Project Needs',
    contact_service_type: 'Service Type',
    contact_service_type_ui_ux: 'UI/UX Product Planning Design',
    contact_service_type_app_development: 'APP Development',
    contact_service_type_website_design: 'Website Design and Construction',
    contact_service_type_other: 'Miscellaneous',
    contact_budget: 'Your Budget',
    contact_budget_50_below: 'Less Than $15,000',
    contact_budget_50_150: 'Between $15,000 and $50,000',
    contact_budget_150_above: 'More Than $50,000',
    contact_budget_no_budget_concept: 'Zero-Budget Concept',
    contact_project_content: 'Project Content',
    contact_project_content_placeholder:
      'Please briefly outline your Project Content to help us understand your needs',
    contact_submit: 'Submit',
    contact_submit_success: 'Sent successfully',
    contact_company_position_title: 'Want to chat with DailyCreative?',
    contact_company_position_title_sub:
      'Welcome to realize our ideals together and create brand value',
    contact_address:
      'Rm. 1430, 12F.-5, No. 144, Sec. 3, Minquan E. Rd., Songshan Dist., Taipei City 105, Taiwan',
  },
  our_services: {
    hero_title: 'Our Services',
    hero_sub_title:
      'Comprehensive exploration of user needs to align with client objectives and enhance product engagement',
    tailor_made_services: 'Tailor-Made Services',
    service_1_title: 'User Experience Design',
    service_1_subtitle: '',
    service_2_title: 'User Interface Design',
    service_2_subtitle: '',
    service_3_title: 'APP Development',
    service_3_subtitle: '',
    service_4_title: 'Front-End Development',
    service_4_subtitle: '',
    service_5_title: 'Back-End Development',
    service_5_subtitle: '',
    service_6_title: 'Operation & Maintenance',
    service_6_subtitle: '',
    project_flow: 'Project Flow',
    project_flow_1_title: 'Planning',
    project_flow_1_item_1_title: 'Define Client Needs & Goals',
    project_flow_1_item_1_text:
      'Requirement interviews, marketing objectives, project goals',
    project_flow_1_item_2_title: 'Product Research',
    project_flow_1_item_2_text:
      'Analyze target users, market trends, and data insights',
    project_flow_1_item_3_title: 'Project Roadmap',
    project_flow_1_item_3_text:
      'Proposal development, budget estimation, timeline planning',
    project_flow_2_title: 'UX Design',
    project_flow_2_item_1_title: 'Feature Mapping',
    project_flow_2_item_1_text:
      'Compile product features based on research and client requirements',
    project_flow_2_item_2_title: 'User Flow Design',
    project_flow_2_item_2_text:
      'Create user flows for primary and secondary product functions',
    project_flow_2_item_3_title: 'Experience Design',
    project_flow_2_item_3_text:
      'Develop wireframes for page layout, functionality, and content hierarchy',
    project_flow_3_title: 'UI Design',
    project_flow_3_item_1_title: 'Visual Concept',
    project_flow_3_item_1_text: 'Propose and finalize main visual style',
    project_flow_3_item_2_title: 'Interface Design',
    project_flow_3_item_2_text: 'Create mockups for main and extended pages',
    project_flow_3_item_3_title: 'Design System',
    project_flow_3_item_3_text:
      'Establish design principles and component documentation',
    project_flow_4_title: 'App Development',
    project_flow_4_item_1_title: 'Framework Setup',
    project_flow_4_item_1_text:
      'Implement interface using latest native system languages',
    project_flow_4_item_2_title: 'Core Development',
    project_flow_4_item_2_text:
      'Build front-end, back-end, and database components',
    project_flow_4_item_3_title: 'Testing & Optimization',
    project_flow_4_item_3_text:
      'Conduct functional tests, optimize performance, ensure cross-platform compatibility',
    project_flow_5_title: 'Front-End Development',
    project_flow_5_item_1_title: 'Custom Web Design',
    project_flow_5_item_1_text:
      'Create bespoke websites tailored to client needs and target users',
    project_flow_5_item_2_title: 'Full-Stack Integration',
    project_flow_5_item_2_text:
      'Implement comprehensive program development for product functionality',
    project_flow_5_item_3_title: 'Database Architecture',
    project_flow_5_item_3_text:
      'Set up efficient systems for resource management',
    project_flow_6_title: 'Back-End Development',
    project_flow_6_item_1_title: 'API Design',
    project_flow_6_item_1_text:
      'Build robust communication between client-side and server',
    project_flow_6_item_2_title: 'Database Implementation',
    project_flow_6_item_2_text:
      'Create structured systems for effective data management',
    project_flow_6_item_3_title: 'Cloud Deployment',
    project_flow_6_item_3_text:
      'Optimize computations and data delivery for users',
    project_flow_7_title: 'Maintenance',
    project_flow_7_item_1_title: 'Launch & Testing',
    project_flow_7_item_1_text:
      'Conduct system integration and user acceptance testing',
    project_flow_7_item_2_title: 'Resource Management',
    project_flow_7_item_2_text:
      'Compile and deliver phase-specific documentation to clients',
    project_flow_7_item_3_title: 'Continuous Improvement',
    project_flow_7_item_3_text:
      'Plan product updates based on user feedback and evolving needs',
    browse_works: 'View Portfolio',
    learn_more: 'Learn More',
    project_1_name: 'Docs4Docs',
    project_2_name: 'SYSTEX eCommerce Service Website',
    project_3_name: 'Pawbo Life',
  },
  project_flow: {
    title: 'Project Flow',
    subtitle:
      'Process consolidation for each phase to lay the foundation for the next phase',
    project_flow_1_title: 'UX (User Experience)',
    project_flow_1_subtitle:
      'Prioritizing user needs while streamlining complex thinking to generate rational and intuitive interactive experiences',
    project_flow_1_item_1_title: 'User Needs and Research',
    project_flow_1_item_1_text_1: `-  User Interviews
          -  Competitive Product Compilation`,
    project_flow_1_item_1_text_2: `-  Market Positioning
          -  Special Features and Functions Consolidation`,
    project_flow_1_item_2_title: 'Consolidate Research and Trends',
    project_flow_1_item_2_text_1: `-  Pros & Cons Comparison Chart of Competitive Products
          -  Competitive Product Feature Analysis`,
    project_flow_1_item_2_text_2: `-  Mission Analysis
          -  Feature Priority Chart`,
    project_flow_1_item_3_title: 'Solutions / Product Sketches',
    project_flow_1_item_3_text_1: `-  User Journey
          -  Content Checklist`,
    project_flow_1_item_3_text_2: `-  Functional Structure Planning
          -  User Flow`,
    project_flow_1_item_4_title: 'Prototype Implementation',
    project_flow_1_item_4_text_1: '-  Wireframe',
    project_flow_1_item_4_text_2: '-  Prototypes',
    project_flow_1_item_5_title: 'Usability Testing',
    project_flow_1_item_5_text_1: '-  User Verification Testing',
    project_flow_1_item_5_text_2: '-  Product Usability Testing',
    project_flow_2_title: 'UI (User Interface) Design',
    project_flow_2_subtitle:
      'Elevating the sensory aesthetic experience through visual juxtaposition of sensibility and beauty',
    project_flow_2_item_1_title: 'Key Visual Proposal and Decision',
    project_flow_2_item_1_text_1: `-  Trend Analysis of Competitive Product Visuals
-  Styling Proposal`,
    project_flow_2_item_1_text_2: `-  UI Reference Consolidation
-  Brand Color Scheme Plan`,
    project_flow_2_item_2_title: 'Mockup Production',
    project_flow_2_item_2_text_1: `-  Key Extension Image Production
-  Flow Illustration`,
    project_flow_2_item_2_text_2: `-  Design of System Icons
-  Application of Scenario and Actual Scene Images`,
    project_flow_2_item_3_title: 'Prototypes and Testing',
    project_flow_2_item_3_text_1: '-  Interactive Interface',
    project_flow_2_item_3_text_2: '-  Mission Implementation Performance',
    project_flow_2_item_4_title: 'Asset Export',
    project_flow_2_item_4_text_1: '-  Export of Design Element Images',
    project_flow_2_item_5_title: 'Flow Document Transfer',
    project_flow_2_item_5_text_1: '-  UX Flow Summary Document',
    project_flow_3_title: 'APP Development',
    project_flow_3_subtitle:
      'Constructing rational and logical structures to materialize the linkage of functional details',
    project_flow_3_item_1_title: 'iOS APP Development',
    project_flow_3_item_1_text_1:
      "Using Apple's latest programming language, Swift, to develop apps for iOS, Apple TV, and Apple Watch",
    project_flow_3_item_2_title: 'Android APP Development',
    project_flow_3_item_2_text_1:
      "Using Android's native programming language to develop apps for the latest Android versions across phones, wearables, tablets, and TVs",
    project_flow_3_item_3_title: 'Flutter Cross-Platform APP Development',
    project_flow_3_item_3_text_1:
      "Utilizing Google's Flutter SDK for mobile app development, creating high-performance, high-fidelity apps for both Android and iOS platforms using the Dart programming language",
    project_flow_3_item_4_title: 'Kotlin Programming Development',
    project_flow_3_item_4_text_1:
      'Leveraging Kotlin, a modern static programming language, to enhance developer productivity, satisfaction, and code security in Android development',
    project_flow_4_title: 'Front-End Development',
    project_flow_4_subtitle:
      'Establishing comprehensive website customization services to optimize business success',
    project_flow_4_item_1_title: 'HTML5',
    project_flow_4_item_1_text_1:
      'Utilizing HTML5 technologies, including HTML, CSS, and JavaScript, to minimize reliance on browser plugins like Adobe Flash, Microsoft Silverlight, and Oracle JavaFX',
    project_flow_4_item_2_title: 'CSS',
    project_flow_4_item_2_text_1: '-  SASS',
    project_flow_4_item_2_text_2: '-  Bootstrap',
    project_flow_4_item_2_text_3: '-  Tailwind',
    project_flow_4_item_3_title: 'JavaScript',
    project_flow_4_item_3_text_1: '-  Vue',
    project_flow_4_item_3_text_2: '-  React',
    project_flow_4_item_3_text_3: '-  jQuery',
    project_flow_5_title: 'Back-End Development',
    project_flow_5_subtitle:
      'Establishing comprehensive website customization services to optimize business success',
    project_flow_5_item_1_title: 'C#',
    project_flow_5_item_1_text_1:
      "ASP.NET, developed by Microsoft, is widely adopted by major corporations and government agencies. Recommended for app back-end development if your company's system is built on .NET or uses Windows Server",
    project_flow_5_item_2_title: 'ASP.NET / ASP.Net Core',
    project_flow_5_item_2_text_1:
      "ASP.NET / ASP.Net Core, developed by Microsoft, is widely used by major corporations and government agencies. Recommended for app back-end development if your company's system is built on .NET or uses Windows Server",
    project_flow_5_item_3_title: 'Node.js',
    project_flow_5_item_3_text_1:
      'Node.js is a high-performance, easily scalable web application development framework, enabling developers to create highly scalable network services without complex calibration or performance fine-tuning',
    project_flow_5_item_4_title: 'Database Implementation',
    project_flow_5_item_4_text_1: `-  Microsoft SQL Server (MSSQL)
-  MySQL
-  MongoDB`,
    project_flow_5_item_5_title: 'Cloud Architecture Design',
    project_flow_5_item_5_text_1: `-  Microsoft Azure
-  Amazon Web Services (AWS)
-  Google Cloud Platform (GCP)`,
    project_flow_5_item_6_title: 'API Integration',
    project_flow_5_item_6_text_1:
      'We have extensive experience in API integration across various systems, capable of processing the integration of apps with original website systems or databases. We can also customize websites to integrate with internal enterprise systems such as SRM or ERP, with professional engineers facilitating the integration',
    project_flow_6_title: 'Operation & Maintenance',
    project_flow_6_subtitle:
      "Thorough verification of products' practicality, sustained scalability, and disciplined user experiences",
    project_flow_6_item_1_title: 'User Feedback Consolidation',
    project_flow_6_item_1_text_1:
      '-  Consolidate product optimization direction based on user feedback',
    project_flow_6_item_2_title: 'Product Iteration Plan',
    project_flow_6_item_2_text_1: `-  Assessment of product iteration
-  Ongoing product maintenance and optimization`,
  },
  project: {
    title: 'Work Portfolio',
    description:
      'With ten-plus years of technic and industry experiences, we provide an all-around customized service covering all major industries',
    project_1_title: 'Docs4Docs',
    project_1_description:
      'The English-Learning platform tailor-made for the medicare professionals',
    project_2_title: 'SYSTEX eCommerce Service Website',
    project_2_description:
      'All-in-One All-Around Integrated eCommerce Platform',
    project_3_title: 'Pawbo Life',
    project_3_description:
      'Interactive Smart Pet Paradise for pets and their owners',
    project_4_title: 'Swiper',
    project_4_description:
      'The most interactive and fun map-based social media APP',
    project_5_title: 'HiStock',
    project_5_description: 'Learn investment with professional investors',
    project_6_title: 'Employment Consulting System',
    project_6_description:
      'A Talent-Hunting Platform for international professionals',
    project_7_title: 'URE BESS',
    project_7_description: 'URE BESS APP',
    project_8_title: 'Focoten',
    project_8_description: 'IoT Cloud Surveillance APP',
    project_9_title: 'IVI System by Advanced & Wise Technology Corp.',
    project_9_description: 'Interface Design of a Smart Auto-based System',
    project_10_title:
      'Digital Instrument Cluster by Advanced & Wise Technology Corp.',
    project_10_description: 'Interface Design of a Smart Auto-based System',
    project_11_title: 'F10',
    project_11_description: 'Online Cloud Surveillance Platform',
    project_12_title: 'ConneXionONE.live',
    project_12_description: 'Enterprise Online Video Conference',
  },
  project_detail: {
    title: 'Project Introduction',
    view_all_works: 'View All Works',
    learn_more: 'Learn More',
    design_target: 'Design Objectives',
  },
  Docs4Docs: {
    BannerDefault_subTitle:
      'National Kaohsiung University of Science and Technology',
    BannerDefault_title: 'Docs4Docs',
    BannerDefault_description:
      'English Learning Platform for Healthcare Professionals',
    ProjectIntroduction_title: 'Project Overview',
    ProjectIntroduction_description: `Docs4Docs is a real-time learning platform that enhances English proficiency for healthcare professionals. It offers industry news, research publications, and a forum for clinical and research professionals to exchange ideas.

Platforms: iOS / Android`,
    SummarySection_title:
      'Cutting-edge English Learning for Healthcare Professionals',
    SummarySection_description:
      'Recognizing the need for improved English skills among healthcare professionals and students, we developed this unique app. We collaborated with clients to create a comprehensive platform, integrating user needs, information architecture, and strategic design to combine diverse learning modules with medical training resources.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Healthcare Professionals',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Healthcare Education',
    SummarySection_secBlockConfig_items_1_title: 'Integrated Learning Modules',
    SummarySection_secBlockConfig_items_1_description:
      'We designed a modular structure to accommodate various learning styles and teaching resources, ensuring quick adoption and easy application by users.',
    SummarySection_secBlockConfig_items_2_title: 'Innovative Visual Design',
    SummarySection_secBlockConfig_items_2_description:
      'Breaking away from the typically stern image of healthcare products, we infused professional blue tones with engaging elements, creating a unique and eye-catching style.',
    SummarySection_secBlockConfig_items_3_title: 'Streamlined Learning Path',
    SummarySection_secBlockConfig_items_3_description:
      "To enhance learning efficiency, we added a bookmark function to each instructional unit, facilitating users' knowledge acquisition process.",
    feature_highlight: 'Key Features',
    FrameA_title_1: 'Docs4Docs Overview',
    FrameA_description_1:
      'Docs4Docs is the first user-friendly app designed for non-native English speakers to learn medical and nursing terminology. Explore MediDocs, MediLinks, MediShare, MediTerms, and MediFavorites for an innovative approach to medical English.',
    FrameA_title_2: 'MediDocs',
    FrameA_description_2:
      'Each MediDocs unit introduces new medical terms, common vocabulary, phrases, and sentences. Test your understanding with unit quizzes and expand your vocabulary through supplementary notes and modular learning.',
    FrameB_title_1: 'MediLinks',
    FrameB_description_1:
      'Access a wealth of medical resources including news, research updates, books, videos, clinical technology films, case studies, and tests.',
    FrameA_title_3: 'MediShare',
    FrameA_description_3:
      'A professional forum for English-speaking healthcare professionals to discuss health and medical topics.',
    FrameB_title_2: 'MediTerms',
    FrameB_description_2:
      'An efficient database for searching medical terms and definitions.',
    FrameB_title_3: 'MediFavorites',
    FrameB_description_3:
      'Bookmark your preferred sections to create a personalized learning path for your medical studies.',
  },
  systex: {
    BannerDefault_subTitle: 'SYSTEX',
    BannerDefault_title: 'SYSTEX Integrated eCommerce Website',
    BannerDefault_description: 'All-in-One eCommerce Service Platform',
    SummarySection_subTitle: 'SYSTEX Integrated eCommerce Website',
    SummarySection_title:
      'Embracing the eCommerce era with digital experience-driven business opportunities',
    SummarySection_description:
      'SYSTEX leverages its seasoned information industry professionals to deliver a brand-new visual presentation. Combined with extensive eCommerce and operational experience, we launch an all-in-one integrated platform offering smarter and more convenient digital experiences for users.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Small and Medium eCommerce Brands',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#eCommerce Service',
    SummarySection_secBlockConfig_items_1_title: 'Visual Redesign',
    SummarySection_secBlockConfig_items_1_description:
      "We redefined the website's key visuals, ensuring consistent styling across all images and icons.",
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      'By refining positioning and restructuring service content, we deliver a more comfortable and streamlined browsing experience for users.',
    SummarySection_secBlockConfig_items_3_title: 'Information Visualization',
    SummarySection_secBlockConfig_items_3_description:
      'We created an intuitive visual language to simplify previously complex professional jargon and service flows.',
    design_highlight: 'Design Highlights',
    feature_highlight: 'Key Features',
    FrameB_title_1: 'Fresh Visual Identity',
    FrameB_description_1:
      'We shaped a refreshing visual outlook using vibrant blue as the primary color, complemented by bright yellow and black line work.',
    FrameA_title_1: 'Consistent Visual Language',
    FrameA_description_1:
      "By integrating service content and features into stylistically consistent visuals, we maintain the integrity of the website and brand's visual tone while strengthening the connection between users and the brand's services.",
    FrameD_title_1: 'Service Information Integration',
    FrameD_description_1:
      'We devised visual elements to guide users through the diverse services and products, ensuring a satisfying browsing experience for all content.',
    FrameA_title_2: 'User-Friendly Information Graphics',
    FrameA_description_2:
      'Considering users may be unfamiliar with these services, we consolidated and simplified complex information into clear graphs and charts for better comprehension.',
  },
  PawboLife: {
    BannerDefault_subTitle: 'PAWBO INC. (ACER Group)',
    BannerDefault_title: 'Pawbo Life',
    BannerDefault_description: 'IoT Smart Pet-Interaction APP',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `Pawbo Life allows you on-demand access to your pets anytime, anywhere while supporting many language versions, such as Chinese, English, French, and Japanese, in accommodating the individual connective specifications and characteristics of individual devices while streamlined paring and operating procedures are planned for easy user adoption. And due to the discrete nature of the information offered by each hardware device, custom-made interfaces, and browsing experiences are designed for information presentation and message transmission.

Platform Supported: iOS & Android`,
    SummarySection_subTitle: 'Pawbo Life',
    SummarySection_title: 'The Interactive Paradise for Pets & Their Owners',
    SummarySection_description:
      "Pawbo Life is a smart pet APP that, when paired with the Crunchy pet feeder, Pawbo+ interactive pet-cam, and iPuppyGo smart pet-token, allows users to remotely interact with and feed their pets. It also monitors pets' daily exercise, sleep patterns, and emotional status in real-time.",
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Pet Owners',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Pet IoT',
    SummarySection_secBlockConfig_items_1_title: 'Brand-New Definition',
    SummarySection_secBlockConfig_items_1_description:
      'We defined and extended interface components, color applications, and product logos based on brand styling and industry design trends.',
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      'By optimizing existing interfaces and definitions, we planned new functional pages to ensure consistent product experience and visual perception.',
    SummarySection_secBlockConfig_items_3_title: 'Modular Function Expansion',
    SummarySection_secBlockConfig_items_3_description:
      'To accommodate product and device expansion, we designed a flexible information architecture that presents functional components in a modular format for future growth.',
    feature_highlight: 'Key Features',
    FrameA_title_1: 'Multiple Device Connection',
    FrameA_description_1:
      'Users can interact with their pets through the app when paired with multiple Crunchy pet feeders, Pawbo+ interactive pet cams, and iPuppyGo pet smart tokens, whether at home or away.',
    FrameA_title_2: 'Real-Time Video and Two-Way Audio',
    FrameA_description_2:
      "Users can set detection areas and activate real-time recording to monitor their pets' indoor movements, even when away from home.",
    FrameB_title_1: 'Smart Automatic Feeding',
    FrameB_description_1:
      "The app controls the Crunchy pet feeder, allowing users to remotely manage feeding, monitor dietary intake, and customize feeding schedules based on their pets' routines.",
    FrameC_title_1: 'Smart Pet Activity Tracking',
    FrameC_description_1:
      "The iPuppyGo pet smart-token provides comprehensive tracking of pets' daily activities, sleep patterns, and emotional states, offering an overview of their health.",
    FrameA_title_3: 'Intelligent Motion Detection',
    FrameA_description_3:
      "Users can define detection areas and enable real-time recording to monitor their pets' movements indoors, even when not at home.",
    FrameA_title_4: 'Event Recording Archive',
    FrameA_description_4:
      'Users can access a detailed archive of all recorded events.',
  },
  Swiper: {
    BannerDefault_subTitle: 'Connect Mobile LTD.',
    BannerDefault_title: 'Swiper',
    BannerDefault_description: 'Discover surprises all over the map',
    SummarySection_subTitle: 'Swiper',
    SummarySection_title: 'The most innovative map-based social media app',
    SummarySection_description:
      'Through location-based social features and a point-accumulation mechanism via map exploration, users can freely collect X-Coin points. By activating the Swiper map, users can explore the latest happenings in their area while earning X-Coins, which can be redeemed for various premium coupons and exclusive lucky draws.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'General Public',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Social Media Platform',
    SummarySection_secBlockConfig_items_1_title: 'Revamped Interface',
    SummarySection_secBlockConfig_items_1_description:
      "We redefined the app's interface style and color scheme, standardizing design elements for overall consistency.",
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      'We optimized product functionality workflows and refined operational behaviors and interactive experiences, providing users with a more comfortable and smooth browsing experience.',
    SummarySection_secBlockConfig_items_3_title: 'Cross-Platform Integration',
    SummarySection_secBlockConfig_items_3_description:
      'New features have been seamlessly integrated into existing functions and logically deployed within corresponding feature groups, allowing users to explore new functionalities more intuitively.',
    feature_highlight: 'Key Features',
    FrameB_title_1: 'Earn Points by Exploring the Map',
    FrameB_description_1:
      'Users can collect X-Coins and experience points for free by visiting marked locations on the map near their current position. These points can be exchanged for various rewards through the coupon mall.',
    FrameA_title_1: 'Privacy-Focused Anonymity Mechanism',
    FrameA_description_1:
      'With the posting ID switching feature, users can freely choose different identities for their posts. For those who prefer to maintain privacy, anonymous posting is also available!',
    FrameA_title_2: 'Local Community',
    FrameA_description_2:
      'The nearby posts feature allows users to instantly browse the latest happenings in their area. Users can also create posts to share their thoughts, deals, and more with other Swiper users.',
    FrameA_title_3: 'Coupon Mall',
    FrameA_description_3:
      'Users can redeem their accumulated X-Coins for various discount coupons in the coupon mall or participate in exclusive lucky draw events.',
  },
  HiStock: {
    BannerDefault_subTitle: 'ISTOCK CO. LTD',
    BannerDefault_title: 'HiStock',
    BannerDefault_description: 'Learn investment with professional investors',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `HiStock was co-founded by partners with over a decade of experience in science parks, sharing a passion for financial investment and a desire to share their insights with the public through an established networking and learning environment.

Platforms Supported: Web / iOS / Android`,
    SummarySection_subTitle: 'HiStock',
    SummarySection_title: 'Your comprehensive financial information guru',
    SummarySection_description:
      'HiStock is an all-in-one investment management app that brings web functionality to mobile, creating a community-forum experience. Users can engage in peer discussions, track favorite columns, and receive mentorship guidance, all while accessing the latest content.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'General Investors',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Investment',
    SummarySection_secBlockConfig_items_1_title:
      'Comprehensive User Experience',
    SummarySection_secBlockConfig_items_1_description:
      'We designed an information framework tailored for mobile users, ensuring an efficient and streamlined browsing experience.',
    SummarySection_secBlockConfig_items_2_title:
      'Web-to-Mobile Feature Mapping',
    SummarySection_secBlockConfig_items_2_description:
      "Through information framework consolidation, we've fully mapped web features such as Online Academy, HiSubscription, Site Newsletter, Course Management, eCommerce platform, and Course Outline to the app.",
    SummarySection_secBlockConfig_items_3_title: 'Integration of New Functions',
    SummarySection_secBlockConfig_items_3_description:
      'To meet customer needs, we implemented highly customized chatroom functions including emoji icons, image uploads, room manager authorization, and notepads, enabling real-time mentor-member interactions.',
    feature_highlight: 'Key Features',
    FrameB_title_1: 'Investment Academy',
    FrameB_description_1:
      'Year-round learning programs offer mentor-used trading tools, comprehensive multimedia courses, and daily chat groups for in-depth learning.',
    FrameA_title_1: 'HiSubscription',
    FrameA_description_1:
      'For those accustomed to online learning or wishing to follow favorite mentors regularly, subscription content and merchandise are available.',
    FrameA_title_2: 'My Courses',
    FrameA_description_2:
      'This section includes subscribed content from the Investment Academy and followed mentor columns, providing direct access to articles, videos, and streaming content.',
    FrameB_title_2: 'Discussion Forum',
    FrameB_description_2:
      'Subscribers to Investment Academy content, HiSubscription, and themed multimedia content gain access to the Discussion Forum, benefiting from broad exchanges with mentors and peers.',
    FrameA_title_3: 'eCommerce Platform',
    FrameA_description_3:
      'Users can easily browse popular content, including premium offerings from the Investment Academy, HiSubscription, investment courses, and event purchase links.',
  },
  EmploymentGoldCard: {
    BannerDefault_subTitle: 'National Development Council',
    BannerDefault_title: 'Employment Gold Card Consultation System',
    BannerDefault_description: 'Overseas Professional Talent Review Platform',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `To facilitate the relocation of overseas professionals to Taiwan for work during the global pandemic, an online review system was created to streamline departmental reviews after the National Immigration Agency receives applications.

Platform Supported: Web`,
    SummarySection_subTitle: 'Consultation System',
    SummarySection_title:
      'Employment Gold Card Consultation System for Overseas Professional Talents',
    SummarySection_description:
      "Through customized process design, we've planned all the necessary information displays to correlate with each department's applicant review roles throughout the various phases of the review process. Our careful planning of processes and interface design facilitates the most effective way to complete candidate reviews, with or without a mandate.",
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Talent-Matching Business',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Corporate Approval System',
    SummarySection_secBlockConfig_items_1_title: 'User Experience',
    SummarySection_secBlockConfig_items_1_description:
      'We provide tailored solutions for the likely usage scenarios encountered by designated review staff in each department, facilitating a more efficient process.',
    SummarySection_secBlockConfig_items_2_title:
      'Information Framework Consolidation',
    SummarySection_secBlockConfig_items_2_description:
      "We've developed a clearly thought-out logic and process-flow analysis to support the specific information framework required at different stages of applicant review, helping every review staff member carry out their assigned tasks effectively.",
    SummarySection_secBlockConfig_items_3_title:
      'Customized Grouped Information',
    SummarySection_secBlockConfig_items_3_description:
      "As each department requires different form content for their specific review phase, we've adopted various form modules based on specific scenarios, such as checklists, inquiry forms, and sorting-label forms.",
    feature_highlight: 'Key Features',
    FrameA_title_1: 'Operation Overview Chart',
    FrameA_description_1:
      'The overview chart provides all involved staff access to the status of each application, including consultations and departmental projects.',
    FrameA_title_2: 'Applications Under Review',
    FrameA_description_2:
      "We've designed a streamlined and efficient operational experience specific to each staff member's responsibilities, providing a creatively formulated application list for the staff-in-charge.",
    FrameA_title_3: 'Stand-In Mandate Designation',
    FrameA_description_3:
      'Department staff going on leave can use this function to designate a stand-in, ensuring smooth review progress.',
    FrameB_title_1: 'Account Management',
    FrameB_description_1:
      'The system setup allows easy management of review personnel accounts and authorization levels, including editing basic information for review staff, their departments, and teams in groups.',
  },
  UREBESS: {
    BannerDefault_subTitle: '',
    BannerDefault_title: 'URE BESS',
    BannerDefault_description: 'Home-Based Energy Storage System App',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `In an era of power shortages, energy storage has become essential for businesses of all sizes and homes alike. URE BESS offers comprehensive Energy Storage System (ESS) solutions for various user segments. This project focuses on providing a home-based ESS solution with a custom-designed monitoring app. The UI/UX design supports both iOS and Android mobile phones and tablets in multiple languages, including Chinese, English, German, and Japanese.

Platforms Supported: iOS / Android`,
    SummarySection_subTitle: 'URE BESS',
    SummarySection_title: 'Creating Self-Sufficient Power Supply',
    SummarySection_description:
      'URE BESS is a smart monitoring app for home-based ESS, providing 24/7 energy supply monitoring and optimization. Through power function, statistics, and real-time ESS surveillance, users can grasp their home power consumption and storage status while improving power usage patterns and reducing electricity bills. The product design caters to the needs of home users of all genders with customized themes.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'App UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Home Users',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#IoT Smart Energy',
    SummarySection_secBlockConfig_items_1_title: 'Design Objectives',
    SummarySection_secBlockConfig_items_1_description:
      'Following the corporate product application guidelines, we designed interface elements and color schemes for the app across various device platforms.',
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      'We created a simple and intuitive interface design that conveys user-specific information through customized charts, graphs, and ESS status displays.',
    SummarySection_secBlockConfig_items_3_title: 'Cross-Platform Applications',
    SummarySection_secBlockConfig_items_3_description:
      'While supporting cross-platform usage for iOS and Android, we designed usage guidelines that align with each system while supporting both mobile phone and tablet operations.',
    feature_highlight: 'Key Features',
    FrameA_title_1: 'Smart Device Monitoring Management',
    FrameA_description_1:
      'Users can remotely monitor battery status through this app. PV-generated power from daytime can be stored to provide power for home consumption at night, allowing easy management of home energy consumption and supply, as well as reducing electricity bills.',
    FrameB_title_1: 'Power Monitoring',
    FrameB_description_1:
      "Users can monitor energy-level status at any time, regardless of their location. This includes PV-generated power usage, ESS power storage status, power purchases and consumption, and power-to-grid transactions, all readily available at users' fingertips.",
    FrameA_title_2: 'Income Management',
    FrameA_description_2:
      'Users can manage power-to-grid transactions, self-consumption premiums, and peak-cut status.',
    FrameA_title_3: 'Statistical Management',
    FrameA_description_3:
      'Real-time access to power generation history, power-grid transactions, PV self-consumption, and ESS usage data.',
  },
  Focoten: {
    BannerDefault_subTitle: 'Orbweb Inc.',
    BannerDefault_title: 'Focoten',
    BannerDefault_description: 'Cloud-Based Smart Video Surveillance Platform',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `Focoten is a platform that upgrades conventional video surveillance systems by utilizing the latest cloud technology, AI, and visual and video processing to enhance security, privacy, and user experience of current surveillance platforms.

Platforms Supported: Web / iOS / Android`,
    SummarySection_subTitle: 'Focoten',
    SummarySection_title:
      'Smart Video Surveillance Platform for Modern Enterprises',
    SummarySection_description:
      'With the Focoten smart surveillance system platform, users can detect and track suspicious individuals or moving objects through surveillance cameras. The system registers marked events, and captured footage is streamed to cloud storage. Users can also designate specific areas for attribute filtering of people or vehicles using AI-powered smart identification.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Enterprise Users',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#IoT Surveillance Applications',
    SummarySection_secBlockConfig_items_1_title: 'Visual Style Redefinition',
    SummarySection_secBlockConfig_items_1_description:
      'Referencing corporate branding and industry design trends, we defined interface components, color applications, and product logo that suit the product, applying them across different devices.',
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      "We meticulously planned the operational experience for individual functions across different platforms. Given the IoT industry's focus on security and privacy, we particularly strengthened the two-factor authentication process in the product.",
    SummarySection_secBlockConfig_items_3_title: 'Cross-Platform Applications',
    SummarySection_secBlockConfig_items_3_description:
      "Supporting cross-platform use on Web, iOS, and Android, we ported web functionalities to mobile apps, designing interfaces that comply with the respective system's design guidelines.",
    feature_highlight: 'Key Features',
    FrameA_title_1: 'Real-Time Cloud Monitoring',
    FrameA_description_1:
      'Focoten provides a 24/7 cloud-based monitoring platform, allowing users to easily view real-time footage and access stored recordings anytime, anywhere.',
    FrameC_title_1: 'People and Vehicle Filtering',
    FrameC_description_1:
      'Users can set individual filtering conditions for people and vehicles, such as specifying clothing and vehicle colors, whether individuals are carrying accessories, and vehicle types (cars, bicycles, motorcycles, buses, trucks). This feature helps users find important footage more efficiently.',
    FrameA_title_2: 'Multi-Channel View Support',
    FrameA_description_2:
      'The multi-channel viewing feature allows users to simultaneously browse live footage from cameras installed in different locations.',
    FrameB_title_1: 'Sharing and Downloading',
    FrameB_description_1:
      'Users can share real-time footage with family or colleagues by sending them a link, with the option to set viewing time limits. Additionally, cloud recordings can be downloaded to local devices (phones, computers), enabling offline viewing.',
    FrameC_title_2: 'Monitoring Dashboard',
    FrameC_description_2:
      'The monitoring dashboard provides an easy overview of device usage. Users can quickly check camera event status, camera device online/offline status, and network usage status at a glance.',
  },
  ZhihanIVISystem: {
    BannerDefault_subTitle: 'Advanced & Wise Technology Corp',
    BannerDefault_title: 'In-Vehicle Infotainment System',
    BannerDefault_description: 'In-Vehicle Application Platform System',
    SummarySection_title: 'Project Introduction',
    SummarySection_description:
      'The in-vehicle human-machine interface serves as a communication bridge between drivers and vehicles. Enhancing the quality of human-vehicle interaction is crucial, encompassing both software interface design and hardware controls.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'HMI GUI',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Car Drivers',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Automotive Applications',
    SummarySection_secBlockConfig_items_1_title: 'Information Focus',
    SummarySection_secBlockConfig_items_1_description:
      'To optimize user experience, key information must be clear and prominent. This allows users to quickly grasp essential details with optimal readability and visibility in any environment, enabling drivers to focus on safe driving.',
    SummarySection_secBlockConfig_items_2_title: 'Efficient Feedback',
    SummarySection_secBlockConfig_items_2_description:
      'Considering the efficiency of information processing in various scenarios, we prioritize safety alerts, warning notifications, and text displays in addition to meeting basic visual display standards. This ensures users receive optimal visual feedback.',
    SummarySection_secBlockConfig_items_3_title: 'Visual Positioning',
    SummarySection_secBlockConfig_items_3_description:
      'Amidst the trend of minimalist modern styles, we incorporate silver-white metallic textures with a touch of retro elegance. This approach sets our design apart from common market offerings, enriching the visual layers.',
    FrameA_subTitleConfig_1_text: 'Inspiration',
    FrameA_title_1: 'Design Concept',
    FrameA_description_1:
      'We blend retro and modern visual elements, striking a balance between understated sophistication and subtle luxury, redefining the concept of retro design.',
    FrameA_subTitleConfig_2_text: 'Design Highlights',
    FrameA_title_2: 'Central Control Homepage',
    FrameA_description_2:
      'The matte metallic texture incorporates contrasting blue elements to highlight overall visual focal points and layering. The framework presents subtle boundaries with a gradient effect, creating clear yet unobtrusive section divisions.',
    FrameA_title_3: 'Device Connectivity',
    FrameA_description_3:
      'Considering various device connection scenarios, we use primary and secondary colors to distinguish states, allowing users to quickly determine the connection status of each device.',
  },
  ZhihanDigitalInstrumentCluster: {
    BannerDefault_subTitle: 'Advanced & Wise Technology Corp',
    BannerDefault_title: 'Digital Instrument Cluster',
    BannerDefault_description: 'In-Vehicle Application Platform System',
    SummarySection_title: 'Project Introduction',
    SummarySection_description:
      "The human-machine interaction experience for motorcycles differs slightly from that of cars. Motorcycle usage scenarios primarily involve hardware control operations. Therefore, it's crucial to consider how to enable riders to execute function settings in the safest manner possible, integrating both software and hardware usage concepts to create an optimal user experience.",
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'HMI GUI',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Motorcycle Riders',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Automotive Applications',
    SummarySection_secBlockConfig_items_1_title: 'Information Focus',
    SummarySection_secBlockConfig_items_1_description:
      'To optimize user experience, key information must be clear and prominent. This allows users to quickly grasp essential details with optimal readability and visibility in any environment, enabling riders to focus on safe driving.',
    SummarySection_secBlockConfig_items_2_title: 'Efficient Feedback',
    SummarySection_secBlockConfig_items_2_description:
      'Considering the efficiency of information processing in various scenarios, we prioritize safety alerts, warning notifications, and text displays in addition to meeting basic visual display standards. This ensures users receive optimal visual feedback.',
    SummarySection_secBlockConfig_items_3_title: 'Integrated Experience',
    SummarySection_secBlockConfig_items_3_description:
      'Considering the all-hardware operation of the digital interface, we use colors and other elements to create clear distinctions between various states, allowing users to experience a synchronized feeling in their operations.',
    FrameA_subTitleConfig_1_text: 'Inspiration',
    FrameA_title_1: 'Design Concept',
    FrameA_description_1:
      'We express the visual impression of speed through minimalist and sleek elements, with metallic textures and sharp accents enhancing visual richness and highlights.',
    FrameA_subTitleConfig_2_text: 'Design Highlights',
    FrameA_title_2: 'Gasoline & Electric Motorcycle Instruments',
    FrameA_description_2:
      "To accommodate the different instrument display information for gasoline and electric motorcycles, we've developed a modular design suitable for both types. The instruments are presented in a minimalist fan-shaped grid format, with red warning zones illuminating when RPM is too high or fuel/battery levels are too low.",
    FrameA_title_3: 'Modular Design',
    FrameA_description_3:
      "To cater to various scenario simulations, we've incorporated multimedia, incoming call notifications, and in-call functions into the home screen, in addition to basic mileage information display. This allows users to quickly access information while riding safely.",
  },
  F10: {
    BannerDefault_subTitle: 'Orbweb Taiwan Inc.',
    BannerDefault_title: 'F10',
    BannerDefault_description: 'Online Cloud Surveillance Platform',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `As security cameras become increasingly prevalent in our society for security and visual detection, it's estimated that there are currently 770 million security cameras installed worldwide, with projections reaching 1 billion units by 2022. Whether we like it or not, they are ubiquitous in our lives. F10 is part of our effort to improve conventional security monitoring systems while revolutionizing the video-feed security systems of the future.

  Platform Supported: Web`,
    SummarySection_subTitle: 'F10',
    SummarySection_title: 'Modern Smart Surveillance Platform for Enterprises',
    SummarySection_description:
      'F10 is built on the latest cloud-computing technology, providing highly economical and scalable real-time video surveillance solutions to suit the needs of all industries.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Enterprise Users',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#IoT Surveillance Platform',
    SummarySection_secBlockConfig_items_1_title: 'Visual Style Extension',
    SummarySection_secBlockConfig_items_1_description:
      'With reference to brand styling and industry design trends, we define and extend interface components, color applications, and product logos across all interfaces.',
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      'By optimizing existing product interfaces and definitions, we plan new functional pages to ensure operational consistency in product experience and visual perceptions.',
    SummarySection_secBlockConfig_items_3_title:
      'Modular Expansion of Functions',
    SummarySection_secBlockConfig_items_3_description:
      'To accommodate the expansion of product functions and devices, we plan flexible information architecture to present functional components in a modular format, catering to future expansion.',
    feature_highlight: 'Key Features',
    FrameB_title_1: 'Product Homepage',
    FrameB_description_1:
      "For introducing product feature highlights, we've planned corresponding images to convey intuitive messages, while the overall design is treated with a simplistic and clean-lined styling.",
    FrameA_title_1: 'Real-Time Surveillance Management',
    FrameA_description_1:
      'F10 provides highly flexible device expansion with unlimited cameras or users. Once connected to the F10 box, users can conveniently manage surveillance cameras whether installed indoors or outdoors, even in places without network connections.',
    FrameC_title_1: 'Historical Data',
    FrameC_description_1:
      'With the historical data function, all events - whether triggered by personnel, vehicles, or gunshots - are thoroughly archived 24/7.',
    FrameA_title_2: 'User Management',
    FrameA_description_2:
      'Provides users with the ability to add users, remove users, edit usernames, and set role permissions.',
    FrameA_title_3: 'Device Hardware Management',
    FrameA_description_3:
      'Real-time access to information such as the online/offline status of devices, offering convenience in managing your F10 box and cameras.',
    FrameC_title_2: 'Smart Surveillance Dashboard',
    FrameC_description_2:
      "Users can browse the platform's usage status anytime, including event logs, camera status, network traffic volume, and more.",
  },
  ConneXionONE: {
    BannerDefault_subTitle: 'Connection Portal Inc.',
    BannerDefault_title: 'ConneXionONE.live',
    BannerDefault_description:
      'Video conferencing service tailored for corporate and educational institutions',
    ProjectIntroduction_title: 'Project Introduction',
    ProjectIntroduction_description: `During the Covid-19 pandemic, the global crisis forced people to work and learn from home. Connection Portal Inc. recognized a new business opportunity by integrating different business models from mainstream products such as Google Meet, Zoom, Cisco Webex, and Microsoft Teams. The DailyCreative team was responsible for product planning and design.

  Platform Supported: Web`,
    SummarySection_subTitle: 'ConneXionONE.live',
    SummarySection_title: 'Essential Tool for Remote Work and Online Learning',
    SummarySection_description:
      'ConneXionONE.live is a comprehensive product designed for online conferencing and learning needs. Its main features include real-time chat, multi-party video conferencing, online collaborative whiteboard, conference and class recording, note-taking, conference voting and Q&A, and group chat rooms. In the face of the ongoing pandemic, ConneXionONE.live is an indispensable tool for your remote work and online learning needs.',
    SummarySection_tagConfigs_1_title: 'Service Type',
    SummarySection_tagConfigs_1_text: 'Web UI/UX',
    SummarySection_tagConfigs_2_title: 'Target Users',
    SummarySection_tagConfigs_2_text: 'Remote Work/Learning',
    SummarySection_tagConfigs_3_title: 'Application Domain',
    SummarySection_tagConfigs_3_text: '#Video Conferencing',
    SummarySection_secBlockConfig_items_1_title: 'Brand-New Definition',
    SummarySection_secBlockConfig_items_1_description:
      'The website color scheme is based on the key visuals of the corporate brand, instilling a sense of stability and integrity. Rounded corners are used to create a more approachable product look and feel.',
    SummarySection_secBlockConfig_items_2_title: 'User Experience Optimization',
    SummarySection_secBlockConfig_items_2_description:
      'By integrating and grouping frequently used primary functions, the overall product experience becomes simpler and more intuitive. Comprehensive planning based on relevant product analysis allows users to quickly adapt to the product.',
    SummarySection_secBlockConfig_items_3_title:
      'Responsive Web Design Support',
    SummarySection_secBlockConfig_items_3_description:
      'Quick adoption through PCs, tablets, or mobile phones, supporting cross-device operation. An intuitive operating interface and experience are defined for different devices, allowing access from anywhere.',
    feature_highlight: 'Key Features',
    FrameA_title_1: 'Online Whiteboard Collaboration',
    FrameA_description_1:
      'Users can collaborate with meeting participants on an online whiteboard to create flowcharts and sketches. Students or colleagues can easily co-create content and download it for easy access during and after online meetings or courses.',
    FrameA_title_2: 'Note Sharing',
    FrameA_description_2:
      'Users can collaborate with meeting participants to create notes. After online meetings or courses, the note content can be exported and saved in various formats such as PDF, Microsoft Word, HTML, etc.',
    FrameB_title_1: 'Meeting Voting and Q&A Activities',
    FrameB_description_1:
      'Through simple online voting and Q&A functions, collect opinions from colleagues and students, encouraging everyone to participate in online interactions and express their ideas.',
    FrameA_title_3: 'External Video Link Sharing',
    FrameA_description_3:
      'Supports YouTube video playback, allowing easy sharing of video content during meeting discussions and online courses.',
    FrameB_title_2: 'Breakout Rooms',
    FrameB_description_2:
      'Create breakout rooms during online meetings. The meeting host can set the duration, number of participants, and assign people to each room. When the allocated time expires, participants automatically return to the main meeting room.',
  },
}
