<template>
  <div class="w-full">
    <BannerDefault class="home_banner-block-size" />
    <div class="home_main-content-container">
      <ServiceItems
        class="mt-4 wl-375:mt-5 wl-480:mt-[1.875rem] wl-748:mt-20 wl-1004:mt-[7.5rem]"
      />
      <Spotlight
        class="mt-8 wl-375:mt-10 wl-480:mt-[3.75rem] wl-748:mt-20 wl-1004:mt-[7.5rem]"
      />
    </div>
    <div
      class="pt-5 wl-375:pt-7 wl-748:pt-20 wl-1004:pt-120px wl-1420:pt-180px"
    >
      <div class="relative wl-748:mt-[8.75rem] wl-1420:mt-24">
        <div
          class="bg-transparent px-16px wl-748:px-0 wl-748:absolute wl-748:top-[-8.75rem] wl-1420:top-[-6rem]"
        >
          <CustomerComments />
        </div>
        <Partners class="mt-5 wl-375:mt-7 wl-480:mt-[1.875rem]" />
      </div>
    </div>
    <FooterContactBox />
  </div>
</template>
<script>
import FooterContactBox from '@/components/custom/box/FooterContactBox'
import BannerDefault from '@/components/custom/page/home/banner/BannerDefault'
import ServiceItems from '@/components/custom/page/home/ServiceItems'
import Spotlight from '@/components/custom/page/home/Spotlight'
import CustomerComments from '@/components/custom/page/home/CustomerComments'
import Partners from '@/components/custom/page/home/Partners'
export default {
  components: {
    FooterContactBox,
    BannerDefault,
    ServiceItems,
    Spotlight,
    CustomerComments,
    Partners,
  },
}
</script>
<style lang="scss">
.home_banner-block-size {
  width: 100%;
  height: 440px;
}
@media screen and (min-width: 375px) {
  .home_banner-block-size {
    height: 540px;
  }
}
@media screen and (min-width: 480px) {
  .home_banner-block-size {
    height: 640px;
  }
}
@media screen and (min-width: 748px) {
  .home_banner-block-size {
    height: 748px;
  }
}
@media screen and (min-width: 1004px) {
  .home_banner-block-size {
    height: 900px;
  }
}
</style>
<style lang="scss" scoped>
.home_main-content-container {
  padding: 0 16px;
}
@media screen and (min-width: 748px) {
  .home_main-content-container {
    padding: 0 32px;
  }
}
@media screen and (min-width: 1004px) {
  .home_main-content-container {
    padding: 0 40px;
  }
}
@media screen and (min-width: 1420px) {
  .home_main-content-container {
    padding: 0 calc(50vw - 600px);
  }
}
</style>
