<template>
  <div
    class="bg-wl-white customer-comments_comment-card-container flex flex-col wl-748:justify-between"
  >
    <div>
      <div
        v-for="(text, textIndex) in texts"
        :key="`home_comment-card-text-${textIndex}`"
        class="except-first:mt-1 w-full whitespace-pre-line text-wl-black-_70 text-wl-12-l wl-375:text-wl-14-l wl-748:text-wl-16-l wl-748:text-ellipsis"
      >
        {{ $t(text) }}
      </div>
    </div>
    <div
      class="w-full font-bold text-right text-wl-dark text-wl-12-l wl-375:text-wl-14-l mt-1 wl-748:mt-[0.375rem] wl-1420:mt-0"
    >
      {{ $t(title) }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    texts: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-comments_comment-card-container {
  border-radius: 16px;
  padding: 1rem;
}
@media screen and (min-width: 480px) {
  .customer-comments_comment-card-container {
    padding: 1rem 1.25rem;
  }
}
@media screen and (min-width: 748px) {
  .customer-comments_comment-card-container {
    padding: 1.5rem 2rem 2rem;
    width: 29rem;
    height: 15rem;
  }
}
@media screen and (min-width: 1420px) {
  .customer-comments_comment-card-container {
    padding: 1.5rem 2.25rem;
    width: 36.75rem;
    height: 12.5rem;
  }
}
</style>
