<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2402_56379)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10.0558C0 15.0275 3.61083 19.1617 8.33333 20V12.7775H5.83333V10H8.33333V7.7775C8.33333 5.2775 9.94417 3.88917 12.2225 3.88917C12.9442 3.88917 13.7225 4 14.4442 4.11083V6.66667H13.1667C11.9442 6.66667 11.6667 7.2775 11.6667 8.05583V10H14.3333L13.8892 12.7775H11.6667V20C16.3892 19.1617 20 15.0283 20 10.0558C20 4.525 15.5 0 10 0C4.5 0 0 4.525 0 10.0558Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2402_56379">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
