<template>
  <div>
    <div
      class="font-bold text-wl-20-s wl-375:text-wl-24-s wl-480:text-wl-28-s wl-748:text-wl-32-l"
    >
      {{ $t('home.services') }}
    </div>
    <div class="text-wl-white-_70 text-wl-14-l wl-375:text-wl-16-l mt-2">
      {{ $t('home.services_subtitle') }}
    </div>
    <div
      class="flex flex-wrap justify-between wl-375:mt-3 wl-480:mt-4 wl-1004:mt-5"
    >
      <SeviceCard
        v-for="serviceConfig in serviceConfigs"
        :key="`home_service-items_service-card-container-${serviceConfig.id}`"
        v-bind="serviceConfig"
        class="mt-3 wl-375:mt-4"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import SeviceCard from './SeviceCard'

const i18n = useI18n()
const serviceConfigs = computed(() => [
  {
    id: 'ux',
    imgSrc: require('@/assets/images/00_Home/home_ic_ux.svg'),
    title: i18n.t('home.ux'),
    subTexts: [i18n.t('home.ux_1'), i18n.t('home.ux_2'), i18n.t('home.ux_3')],
  },
  {
    id: 'ui',
    imgSrc: require('@/assets/images/00_Home/home_ic_ui.svg'),
    title: i18n.t('home.ui'),
    subTexts: [i18n.t('home.ui_1'), i18n.t('home.ui_2')],
  },
  {
    id: 'hmi',
    imgSrc: require('@/assets/images/00_Home/home_ic_hmi.svg'),
    title: i18n.t('home.hmi'),
    subTexts: [i18n.t('home.hmi_1'), i18n.t('home.hmi_2')],
  },
  {
    id: 'app',
    imgSrc: require('@/assets/images/00_Home/home_ic_develop.svg'),
    title: i18n.t('home.app_web_programming'),
    subTexts: [
      i18n.t('home.app_web_programming_1'),
      i18n.t('home.app_web_programming_2'),
    ],
  },
])
</script>
