export default [
  {
    id: '1',
    title: 'home.testimonial_1_title',
    texts: ['home.testimonial_1_texts_1'],
  },
  {
    id: '2',
    title: 'home.testimonial_2_title',
    texts: ['home.testimonial_2_texts_1', 'home.testimonial_2_texts_2'],
  },
  {
    id: '3',
    title: 'home.testimonial_3_title',
    texts: ['home.testimonial_3_texts_1'],
  },
  {
    id: '4',
    title: 'home.testimonial_4_title',
    texts: ['home.testimonial_4_texts_1'],
  },
  {
    id: '5',
    title: 'home.testimonial_5_title',
    texts: ['home.testimonial_5_texts_1'],
  },
  {
    id: '6',
    title: 'home.testimonial_6_title',
    texts: ['home.testimonial_6_texts_1'],
  },
  {
    id: '7',
    title: 'home.testimonial_7_title',
    texts: ['home.testimonial_7_texts_1'],
  },
  {
    id: '8',
    title: 'home.testimonial_8_title',
    texts: ['home.testimonial_8_texts_1', 'home.testimonial_8_texts_2'],
  },
]
