<template>
  <div
    class="home_service-items_sevice-card_container bg-wl-white-_5 flex items-center wl-1004:flex-col wl-1004:items-start"
  >
    <img
      class="home_service-items_sevice-card_image"
      :src="imgSrc"
      :alt="`${title} Image`"
    />
    <div
      class="bg-transparent ml-16px wl-375:ml-24px wl-480:ml-32px wl-748:ml-20px wl-1004:ml-0 wl-1004:mt-8px wl-1420:mt-12px"
    >
      <div
        class="font-bold wl-1004:w-full text-wl-16-s wl-375:text-wl-18-s wl-480:text-wl-20-s wl-748:text-wl-24-s"
      >
        {{ title }}
      </div>
      <div class="mt-1 wl-480:mt-2 wl-1004:mt-3">
        <div
          v-for="(subText, subTextIndex) in subTexts"
          :key="`home_service-items_sevice-card-${title}-${subTextIndex}`"
          class="text-wl-white-_70 text-wl-14-l wl-375:text-wl-16-l"
        >
          {{ subText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subTexts: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style lang="scss" scoped>
.home_service-items_sevice-card_container {
  border-radius: 16px;
  padding: 0 14px;
  height: 8rem;
  width: 100%;
}
.home_service-items_sevice-card_image {
  width: 5.625rem;
  height: 5.625rem;
}
@media screen and (min-width: 375px) {
  .home_service-items_sevice-card_container {
    padding: 0 16px;
    height: 9rem;
  }
  .home_service-items_sevice-card_image {
    width: 6.25rem;
    height: 6.25rem;
  }
}
@media screen and (min-width: 480px) {
  .home_service-items_sevice-card_container {
    padding: 0 44px;
    height: 10rem;
  }
  .home_service-items_sevice-card_image {
    width: 8.5rem;
    height: 8.5rem;
  }
}

@media screen and (min-width: 748px) {
  .home_service-items_sevice-card_container {
    padding: 0 12px;
    height: 11.875rem;
    width: calc((100% - 16px) / 2);
  }
  .home_service-items_sevice-card_image {
    width: 7.85rem;
    height: 7.85rem;
  }
}
@media screen and (min-width: 1004px) {
  .home_service-items_sevice-card_container {
    padding: 16px 24px;
    height: 22rem;
    width: calc((100% - 48px) / 4);
  }
  .home_service-items_sevice-card_image {
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1420px) {
  .home_service-items_sevice-card_container {
    padding: 20px 24px;
    height: 25rem;
    width: calc((100% - 72px) / 4);
  }
  .home_service-items_sevice-card_image {
    width: 12.5rem;
    height: 12.5rem;
    margin: 0 auto;
  }
}
</style>
