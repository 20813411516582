<template>
  <div
    class="layout-header-default_container h-header wl-1004:h-header-md"
    :class="[themeClass.bg]"
  >
    <div
      class="bg-transparent flex w-full justify-between items-center h-full mx-16px wl-748:mx-32px wl-1004:mx-40px wl-1420:mx-[calc(50vw-600px)]"
    >
      <router-link :to="{ name: 'home' }">
        <img
          class="block w-[212px] h-[18.4px] wl-375:w-[232px] wl-375:h-[20.06px] wl-748:w-auto wl-748:h-auto"
          :src="logoSrc"
          alt="Logo Image"
        />
      </router-link>
      <!-- mobile menu -->
      <button
        @click="isOpenMobileMenu = !isOpenMobileMenu"
        class="block wl-1004:hidden"
      >
        <component
          :is="mobileMenuIcon"
          class="block w-32px h-32px"
          :class="[themeClass.fill]"
        />
      </button>
      <div
        :style="{
          height: 'calc(100vh - 56px)',
          transform: isOpenMobileMenu ? 'translateX(0)' : 'translateX(100%)',
        }"
        class="wl-1004:hidden w-screen transition-transform ease-out duration-wl-600 absolute top-header left-0 py-40px overflow-hidden"
        :class="[themeClass.bg]"
      >
        <template
          v-for="navbarItem in navbarItems"
          :key="`layout-header-default_navbar-item-${navbarItem.id}`"
        >
          <div class="w-fit mx-auto mb-24px">
            <a
              v-if="navbarItem.externalSrc"
              @click="isOpenMobileMenu = false"
              class="text-wl-16-l block focus:text-wl-primary"
              :href="navbarItem.externalSrc"
              target="_blank"
              rel="noopener noreferrer"
              :class="[themeClass.text]"
            >
              {{ $t(navbarItem.text) }}
            </a>
            <router-link
              v-else-if="navbarItem.routeName"
              @click="isOpenMobileMenu = false"
              :to="{ name: navbarItem.routeName }"
              class="text-wl-16-l block"
              :class="[themeClass.text]"
            >
              {{ $t(navbarItem.text) }}
            </router-link>
          </div>
        </template>
        <!-- Language Switcher for Mobile -->
        <div class="w-fit mx-auto mb-24px">
          <button
            @click="toggleLanguage"
            class="text-wl-16-l flex items-center focus:text-wl-primary"
            :class="[themeClass.text]"
          >
            <IconGlobe
              width="20"
              height="20"
              class="transition-wl-default block mr-2"
            />
            {{ currentLanguage }}
          </button>
        </div>
      </div>
      <!-- md navbar -->
      <div class="hidden wl-1004:flex items-center justify-between h-full">
        <button
          v-for="navbarItemMd in navbarItemsMd"
          :key="`layout-header-default_md-navbar-${navbarItemMd.id}`"
          tabindex="0"
          @mouseenter="navbarHoverId = navbarItemMd.id"
          @mouseleave="navbarHoverId = ''"
          @blur="
            navbarFocusId === navbarItemMd.id ? (navbarFocusId = '') : () => {}
          "
          @click="
            () => {
              if (!navbarItemMd.children || !navbarItemMd.children.length) {
                $router.push({ name: navbarItemMd.routeName })
                return
              }
              if (navbarFocusId === navbarItemMd.id) {
                navbarFocusId = ''
                navbarHoverId = ''
              } else navbarFocusId = navbarItemMd.id
            }
          "
          class="h-full flex items-center wl-transition-default"
          :class="
            navbarFocusId === navbarItemMd.id ||
            navbarHoverId === navbarItemMd.id
              ? 'text-wl-primary'
              : themeClass.text
          "
        >
          <!-- mr-32px wl-1004:mr-48px -->
          <NavbarDropdown
            :themeClass="themeClass"
            :item="navbarItemMd"
            :isDisplayDropdown="
              navbarFocusId === navbarItemMd.id ||
              navbarHoverId === navbarItemMd.id
            "
          />
        </button>
        <a
          class="flex items-center ml-16px wl-1004:ml-24px h-full wl-transition-default"
          :class="[themeClass.fill]"
          href="https://www.facebook.com/welovedailycreative"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconFacebook
            width="20"
            height="20"
            class="transition-wl-default block"
          />
        </a>
        <!-- Language Switcher for Desktop -->
        <button
          @click="toggleLanguage"
          class="flex items-center justify-end text-wl-14-l ml-16px wl-1004:ml-24px h-full wl-transition-default"
          :class="[themeClass.fill, themeClass.text]"
        >
          <IconGlobe
            width="20"
            height="20"
            class="transition-wl-default block mr-2"
          />
          <span class="flex justify-end min-w-[30px]">
            {{ currentLanguage }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { getPreferredLanguage } from '@/i18n'
import IconFacebook from '@/components/svg/icon/IconFacebook'
import IconGlobe from '@/components/svg/icon/IconGlobe'
import navbarItems from './navbarItems'
import navbarItemsMd from './navbarItemsMd'
import NavbarDropdown from './NavbarDropdown'

const themeModeEnum = {
  LIGHT: 'LIGHT',
  DEFAULT: 'DEFAULT',
}

export default {
  components: {
    IconFacebook,
    IconGlobe,
    NavbarDropdown,
  },
  setup() {
    const { locale, t } = useI18n()

    const setInitialLanguage = () => {
      const preferredLang = getPreferredLanguage()
      locale.value = preferredLang
    }

    const toggleLanguage = () => {
      const newLang = locale.value === 'en' ? 'zh-tw' : 'en'
      locale.value = newLang
      try {
        localStorage.setItem('preferredLanguage', newLang)
      } catch (error) {
        console.error('Error saving preferred language to localStorage:', error)
      }
    }

    setInitialLanguage()

    return { toggleLanguage, t }
  },
  data() {
    return {
      navbarItems,
      navbarItemsMd,
      navbarFocusId: '',
      navbarHoverId: '',
      isOpenMobileMenu: false,
    }
  },
  watch: {
    $route() {
      this.isOpenMobileMenu = false
      this.navbarFocusId = ''
    },
  },
  computed: {
    mobileMenuIcon() {
      return this.isOpenMobileMenu
        ? defineAsyncComponent(() =>
            import('@/components/svg/icon/IconCancel.vue')
          )
        : defineAsyncComponent(() =>
            import('@/components/svg/icon/IconMenu.vue')
          )
    },
    themeMode() {
      if (this.$route && this.$route.name === 'project-_id') {
        return themeModeEnum.LIGHT
      }
      return themeModeEnum.DEFAULT
    },
    logoSrc() {
      if (this.themeMode === themeModeEnum.LIGHT) {
        return require('@/assets/images/07_Others/img_dailycreative_logo_b.svg')
      }
      return require('@/assets/images/07_Others/img_dailycreative_logo.svg')
    },
    themeClass() {
      if (this.themeMode === themeModeEnum.LIGHT) {
        return {
          bg: 'bg-wl-white',
          text: 'text-wl-dark wl-748:hover:text-wl-primary',
          fill: 'fill-wl-dark wl-748:hover:fill-wl-primary',
          dropdownBg: 'bg-[#f3f3f3]',
          dropdownText: 'text-wl-grey-sec wl-748:hover:text-wl-dark',
        }
      }
      return {
        bg: 'bg-wl-bg',
        text: 'text-wl-white',
        fill: 'fill-wl-white wl-748:hover:fill-wl-primary',
        dropdownBg: 'bg-[#252525]',
        dropdownText: 'text-wl-white-_70 wl-748:hover:text-wl-white',
      }
    },
    currentLanguage() {
      return this.$i18n.locale === 'en' ? 'EN' : '中文'
    },
  },
}
</script>
<style lang="scss">
/* 要使用其他寬度，可在此組件外添加其他 class */
.layout-header-default_container {
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
/* logo */
.layout-header-default_logo-margin-right {
  margin-right: 43px;
}

@media screen and (min-width: 375px) {
  .layout-header-default_logo-margin-right {
    margin-right: 77px;
  }
}

@media screen and (min-width: 480px) {
  .layout-header-default_logo-margin-right {
    margin-right: 184px;
  }
}

@media screen and (min-width: 748px) {
  .layout-header-default_logo-margin-right {
    margin-right: 84px;
  }
}

@media screen and (min-width: 1004px) {
  .layout-header-default_logo-margin-right {
    margin-right: 260px;
  }
}

@media screen and (min-width: 1420px) {
  .layout-header-default_logo-margin-right {
    margin-right: 516px;
  }
}
</style>
<style lang="scss"></style>
