<template>
  <div class="home_partners-container">
    <div>
      <div
        class="font-bold text-wl-20-s wl-375:text-wl-24-s wl-480:text-wl-28-s wl-748:text-wl-32-s"
      >
        {{ $t('home.collaborative_title') }}
      </div>
      <div
        :class="`text-wl-white-_70
            mt-1 wl-480:mt-2
            text-wl-14-l wl-375:text-wl-16-l
        `"
      >
        {{ $t('home.collaborative_subtitle') }}
      </div>
      <div
        class="flex flex-wrap mt-5 wl-375:mt-7 wl-480:mt-8 wl-1420:mt-9 wl-1900:mt-8"
      >
        <div
          v-for="(partnerLogoSrc, partnerLogoSrcIndex) in partnerLogoSrcs"
          :key="`home_partners-partner-logo-image-${partnerLogoSrcIndex}`"
          class="home_partners-partners-logo-image relative"
        >
          <img
            :src="partnerLogoSrc.src"
            alt="partners logo"
            class="w-full h-full"
          />
          <img
            src="@/assets/images/00_Home/home_img_plus.svg"
            alt="plus decoration icon"
            :class="`
              absolute w-16px h-16px bottom-[-20px] right-[-20px]
              home_partners-partners-logo-image-plus-decoration
            `"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mainConfig from '@/config/main'
const partnerLogoSrcs = []
for (let index = 1; index <= mainConfig.partnerLogoCount; index++) {
  partnerLogoSrcs.push({
    src: require(`@/assets/images/partnerLogos/home_img_logo_${index}@2x.png`),
  })
}
export default {
  data() {
    return {
      partnerLogoSrcs,
    }
  },
}
</script>
<style lang="scss" scoped>
.home_partners-container {
  padding: 24px 16px 32px;
  background: url('@/assets/images/00_Home/home_img_partner@2x.jpg') no-repeat
    top center;
  background-size: cover;
}
.home_partners-partners-logo-image {
  width: calc(50% - 8px);
  &:nth-child(even) {
    margin-left: 16px;
  }
}
.home_partners-partners-logo-image-plus-decoration {
  display: none;
}
@media screen and (min-width: 375px) {
  .home_partners-container {
    padding: 32px 16px;
  }
}
@media screen and (min-width: 480px) {
  .home_partners-container {
    padding: 40px 16px 44px;
  }
  .home_partners-partners-logo-image {
    width: calc(33% - 9px);
    &:nth-child(even) {
      margin-left: 0px;
    }
    &:nth-child(n + 4) {
      margin-top: 14px;
    }
    &:not(:nth-child(3n + 3)) {
      margin-right: 14px;
    }
  }
}
@media screen and (min-width: 748px) {
  .home_partners-container {
    padding: 172px 32px 40px;
  }
  .home_partners-partners-logo-image {
    width: calc(33% - 10.5px);

    &:nth-child(n + 4) {
      margin-top: 24px;
    }

    &:not(:nth-child(3n + 3)) {
      margin-right: 16px;
      .home_partners-partners-logo-image-plus-decoration {
        display: block;
      }
    }
    &:nth-last-child(-n + 3) {
      .home_partners-partners-logo-image-plus-decoration {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1004px) {
  .home_partners-container {
    padding: 182px 40px 80px;
  }
  .home_partners-partners-logo-image {
    width: calc(25% - 12px);
    &:not(:nth-child(3n + 3)) {
      .home_partners-partners-logo-image-plus-decoration {
        display: none;
      }
    }
    &:nth-last-child(-n + 3) {
      .home_partners-partners-logo-image-plus-decoration {
        display: none;
      }
    }
    &:nth-child(4n + 4) {
      margin-right: 0;
    }

    &:nth-child(n + 4) {
      margin-top: 0;
    }

    &:nth-child(n + 5) {
      margin-top: 24px;
    }
    &:not(:nth-child(4n + 4)) {
      margin-right: 16px;
      .home_partners-partners-logo-image-plus-decoration {
        display: block;
      }
    }
    &:nth-last-child(-n + 4) {
      .home_partners-partners-logo-image-plus-decoration {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1420px) {
  .home_partners-container {
    padding: 182px calc(50vw - 600px) 75px;
  }
  .home_partners-partners-logo-image {
    width: calc(25% - 18px);
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
    &:not(:nth-child(4n + 4)) {
      margin-right: 24px;
    }

    &:nth-child(n + 5) {
      margin-top: 16px;
    }
  }
}
@media screen and (min-width: 1900px) {
  .home_partners-container {
    padding: 182px calc(360px + 50vw - 950px) 80px;
  }
}
</style>
