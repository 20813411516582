export default [
  {
    id: 'services-item-container',
    text: 'header.services',
    children: [
      {
        id: 'our-services',
        text: 'header.our_services',
        routeName: 'our-services',
      },
      {
        id: 'project-flow',
        text: 'header.project_flow',
        routeName: 'project-flow',
      },
    ],
  },
  {
    id: 'project',
    text: 'header.works',
    routeName: 'project',
  },
  {
    id: 'about-us',
    text: 'header.about_us',
    routeName: 'about-us',
  },
  {
    id: 'contact-us',
    text: 'header.contact_us',
    routeName: 'contact-us',
  },
]
