<template>
  <div class="relative h-full flex items-center">
    <!-- 不控制選單文字顏色，都用繼承的，因為目前室外容器決定某些顏色變化 -->
    <div
      class="text-wl-14-l px-16px wl-1004:px-24px h-full flex items-center z-50 relative"
      :class="[themeClass.bg]"
    >
      {{ $t(item.text) }}
    </div>
    <!-- transform: isDisplayDropdown ? 'scaleY(1)' : 'scaleY(0)', -->
    <!-- height: isDisplayDropdown
          ? `${navbarMdChildrenContainerHeight - dropdownPaddingY * 2}px`
          : '0', -->

    <!-- display: isDisplayDropdown ? 'block' : 'none', -->
    <!-- padding: isDisplayDropdown ? dropdownPadding : '0',
        display: isDisplayDropdown ? 'block' : 'none', -->
    <div
      v-if="item.children && item.children.length"
      class="w-120px absolute left-0"
      :class="[themeClass.bg]"
      style="transform: translateY(-250%); z-index: 41"
      :style="{
        bottom: `-${navbarMdChildrenContainerHeight}px`,
        height: `${navbarMdChildrenContainerHeight}px`,
      }"
    ></div>
    <div
      v-if="item.children && item.children.length"
      :style="{
        bottom: `-${navbarMdChildrenContainerHeight}px`,
        padding: dropdownPadding,
        transform: isDisplayDropdown ? 'translateY(0)' : 'translateY(-250%)',
      }"
      class="layout-header-default_navbar-dropdown_navbar-children-block z-40"
      :class="[themeClass.dropdownBg]"
    >
      <router-link
        v-for="(itemChild, itemChildIndex) in item.children"
        :key="`layout-header-default_navbar-dropdown_child-${itemChild.id}`"
        :to="{ name: itemChild.routeName }"
        @click.stop
        class="block"
      >
        <div
          :style="{
            fontSize: dropdownText.fontSize,
            lineHeight: dropdownText.lineHeight,
            marginTop:
              itemChildIndex === 0 ? '0' : dropdownItemDistanceBetweenOther,
          }"
          class="whitespace-nowrap wl-transition-default"
          :class="[themeClass.dropdownText]"
        >
          {{ $t(itemChild.text) }}
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
const dropdownPaddingX = 22
// 因為 paddingY 與 font 需要拿來算高度，所以統一拉出來避免有更新時沒修改到
const dropdownPaddingY = 16
const dropdownFontSizeRem = 0.875
const dropdownLineHeightEm = 1.6
const dropdownItemDistanceBetweenOtherPx = 8
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isDisplayDropdown: {
      type: Boolean,
      default: false,
    },
    /**
     * {
     *  bg: '',
     *  text: '',
     * }
     */
    themeClass: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dropdownPaddingX,
      dropdownPaddingY,
      dropdownPadding: `${dropdownPaddingY}px ${dropdownPaddingX}px`,
      dropdownText: {
        fontSize: `${dropdownFontSizeRem}rem`,
        lineHeight: `${dropdownLineHeightEm}em`,
      },
      dropdownItemDistanceBetweenOther: `${dropdownItemDistanceBetweenOtherPx}px`,
    }
  },

  computed: {
    // 若文字要支援多行需要調整
    navbarMdChildrenContainerHeight() {
      if (!this.item.children || !this.item.children.length) return 0
      const remPx = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      )
      const dropdownFontSize = dropdownFontSizeRem * remPx
      const dropdownLineHeight = dropdownFontSize * dropdownLineHeightEm
      const children = this.item.children
      return (
        dropdownLineHeight * children.length +
        (children.length - 1) * dropdownItemDistanceBetweenOtherPx +
        dropdownPaddingY * 2
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.transtion-1 {
  transition: all 0.1s cubic-bezier(0.42, 0, 1, 1);
}
</style>
<style lang="scss">
.layout-header-default_navbar-dropdown_navbar-children-block {
  position: absolute;
  border-radius: 4px;
  left: 0%;
  overflow: hidden;
  /* transition: height 0.1s cubic-bezier(0.42, 0, 1, 1); */
  transition: transform 0.5s ease-out;

  /* animation: growDown 250ms cubic-bezier(0, 0, 0.7, 1.09) forwards; */
  /* transform-origin: top center; */
  /* animation: opacityVisiable 400ms cubic-bezier(0, 0, 0.7, 1.09) forwards;
  @keyframes opacityVisiable {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    90% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  } */

  /* @keyframes growDown {
    0% {
      transform: scaleY(0%);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(100%);
    }
  } */
}
</style>
