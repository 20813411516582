import { createI18n } from 'vue-i18n'
import en from './locales/en'
import tc from './locales/tc'

export const getPreferredLanguage = () => {
  try {
    const storedLang = localStorage.getItem('preferredLanguage')
    if (storedLang) {
      return storedLang
    }
  } catch (error) {
    console.error('Error getting preferred language:', error)
  }

  const browserLang = navigator.language || navigator.userLanguage
  return browserLang.startsWith('zh') ? 'zh-tw' : 'en'
}
const i18n = createI18n({
  legacy: false,
  locale: getPreferredLanguage(),
  fallbackLocale: 'en',
  messages: {
    en,
    'zh-tw': tc,
  },
})

export default i18n
