export default [
  {
    id: 'our-services',
    text: 'header.services',
    routeName: 'our-services',
  },
  {
    id: 'project-flow',
    text: 'header.project_flow',
    routeName: 'project-flow',
  },
  {
    id: 'project',
    text: 'header.works',
    routeName: 'project',
  },
  {
    id: 'about-us',
    text: 'header.about_us',
    routeName: 'about-us',
  },
  {
    id: 'contact-us',
    text: 'header.contact_us',
    routeName: 'contact-us',
  },
  {
    id: 'facebook',
    text: 'header.facebook',
    externalSrc: 'https://www.facebook.com/welovedailycreative',
  },
]
