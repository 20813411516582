<template>
  <!-- h-[238px] wl-375:h-[325px] wl-480:h-[365px] wl-748:h-[400px] -->
  <div
    :class="`
        footer-contact-box-container
        w-full bg-wl-bg flex items-center justify-center
        py-8 wl-375:py-[3.75rem] wl-480:py-20 wl-748:py-[6.25rem] wl-1004:py-[8.875rem] wl-1420:py-[9.125rem]
        px-16px wl-748:px-0
    `"
  >
    <div
      class="wl-1004:flex flex-wrap items-center w-full h-full justify-center text-center wl-1004:text-left"
    >
      <div class="wl-1004:max-w-[360px]">
        <div
          class="font-bold text-wl-20-l wl-375:text-wl-28-s wl-748:text-wl-32-s"
        >
          {{ $t('home.contact_us_title') }}
        </div>
        <div
          :class="`
            text-wl-14-l wl-375:text-wl-16-l
            text-wl-white-_70
            mt-20px wl-375:mt-24px wl-748:mt-8px
            wl-1004:mb-4px
        `"
        >
          {{ $t('home.contact_us_subtitle_1') }}
          <br class="hidden wl-748:inline" />
          {{ $t('home.contact_us_subtitle_2') }}
        </div>
      </div>
      <ArrrowLarge
        :class="`
            h-8px w-[340px] wl-1420:w-[589px] wl-1420:h-[10px]
            ml-48px wl-1420:ml-[56px]
            mt-[4.375rem] mb-[1.875rem] hidden wl-1004:block
        `"
      />
      <button
        :class="`
            font-bold rounded-4px bg-wl-primary hover:brightness-125 text-wl-16-l wl-375:text-wl-18-l w-180px h-48px
            transition-wl-all ease-wl-default duration-500
            wl-1004:ml-24px
            mt-8 wl-375:mt-10 wl-1004:mt-[3rem] wl-1004:mb-1.5 wl-1420:mt-[3.25rem]
        `"
        @click="$router.push({ name: 'contact-us' })"
      >
        {{ $t('home.contact_us') }}
      </button>
    </div>
  </div>
</template>
<script>
import ArrrowLarge from '@/components/svg/frozenColor/ArrrowLarge'
export default {
  components: {
    ArrrowLarge,
  },
}
</script>
<style lang="scss" scoped>
.footer-contact-box-container {
  border-top: 1px solid #2e2e2e;
}
</style>
