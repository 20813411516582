<template>
  <div class="bg-transparent">
    <!-- mobile -->
    <div class="wl-748:hidden">
      <CommentCard
        v-for="comment in commentsDisplayMobile"
        :key="`customer-comment-card-mobile-${comment.id}`"
        :title="comment.title"
        :texts="comment.texts"
        class="except-first:mt-3"
      />
      <div v-if="!isDisplayAllCommentMobile" class="w-full text-right">
        <button
          class="w-fit wl-transition-default hover:brightness-75"
          @click="isDisplayAllCommentMobile = !isDisplayAllCommentMobile"
        >
          <div class="mt-2 text-wl-white-_70 text-wl-14-l">
            {{ $t('home.readmore') }}
          </div>
        </button>
      </div>
    </div>
    <!-- computer -->
    <div
      class="relative bg-transparent hidden w-full overflow-x-hidden max-w-[100vw] animation-hover-stop wl-748:flex"
    >
      <div
        class="bg-transparent flex whitespace-nowrap animation-marquee"
        :style="{ animationDuration: `${comments.length * 9}s` }"
      >
        <CommentCard
          v-for="comment in comments"
          :key="`customer-comment-card-computer-${comment.id}`"
          :title="comment.title"
          :texts="comment.texts"
          class="mx-2"
        />
      </div>

      <div
        class="bg-transparent absolute top-0 flex whitespace-nowrap animation-marquee-2"
        :style="{ animationDuration: `${comments.length * 9}s` }"
      >
        <CommentCard
          v-for="comment in comments"
          :key="`customer-comment-card-computer-${comment.id}`"
          :title="comment.title"
          :texts="comment.texts"
          class="mx-2"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import comments from '@/config/homePageComments'
import CommentCard from './CommentCard'

const isDisplayAllCommentMobile = ref(false)

const commentsDisplayMobile = computed(() => {
  return isDisplayAllCommentMobile.value ? comments : comments.slice(0, 3)
})
</script>
<style lang="scss" scoped>
@media screen and (min-width: 748px) {
  .animation-hover-stop {
    &:hover {
      animation-play-state: paused;
    }
  }
  .animation-marquee {
    animation: marquee 72s linear infinite;
    animation-play-state: inherit;
    @keyframes marquee {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
  .animation-marquee-2 {
    animation: marquee2 72s linear infinite;
    animation-play-state: inherit;

    @keyframes marquee2 {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
  }
}
</style>
