import { createRouter, createWebHistory } from 'vue-router'
import LayoutMain from '@/layouts/layout-main'
import Home from '../views/index.vue'

const routes = [
  {
    path: '/',
    component: LayoutMain,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: 'our-services',
        name: 'our-services',
        component: () =>
          import(/* webpackChunkName: "our-services" */ '@/views/our-services'),
      },
      {
        path: 'project-flow',
        name: 'project-flow',
        component: () =>
          import(/* webpackChunkName: "project-flow" */ '@/views/project-flow'),
      },
      {
        path: 'project',
        children: [
          {
            path: '',
            name: 'project',
            component: () =>
              import(/* webpackChunkName: "project" */ '@/views/project'),
          },
          {
            path: ':id',
            name: 'project-_id',
            component: () =>
              import(
                /* webpackChunkName: "project-_id" */ '@/views/project/_id'
              ),
          },
        ],
      },
      {
        path: 'about-us',
        name: 'about-us',
        component: () =>
          import(/* webpackChunkName: "about-us" */ '@/views/about-us'),
      },
      {
        path: 'contact-us',
        name: 'contact-us',
        component: () =>
          import(/* webpackChunkName: "contact-us" */ '@/views/contact-us'),
      },
    ],
  },
  // {
  //   path: '/about-us',
  //   name: 'about-us',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
    return { top: 0 }
  },
})

export default router
