<template>
  <div
    :class="[
      themeClass.bg,
      `
        w-full flex justify-between
        flex-wrap wl-1004:flex-nowrap
        px-16px wl-748:px-32px wl-1004:px-40px wl-1420:px-[calc(50vw-600px)]
        items-start wl-1004:items-center
        py-20px wl-748:py-24px wl-1004:py-0
    `,
    ]"
  >
    <div
      class="flex items-center w-full wl-1004:w-auto flex-wrap wl-1004:flex-nowrap"
      :class="themeClass.text"
    >
      <div class="flex items-center mr-40px w-full wl-1004:w-auto">
        <div class="text-wl-12-l wl-375:text-wl-16-l font-extrabold w-40px">
          TEL
        </div>
        <div class="text-wl-12-l wl-375:text-wl-16-l ml-16px">02-2876-8088</div>
      </div>
      <div
        class="flex items-center mr-40px w-full wl-1004:w-auto mt-4px wl-1004:mt-0"
      >
        <div class="text-wl-12-l wl-375:text-wl-16-l font-extrabold w-40px">
          MAIL
        </div>
        <div class="text-wl-12-l wl-375:text-wl-16-l ml-16px">
          business@dailycreative.com.tw
        </div>
      </div>
    </div>
    <div
      class="flex-items-center mt-8px wl-1004:mt-0"
      :class="themeClass.ccText"
    >
      <div class="text-wl-12-l wl-480:text-wl-14-l">
        © Copyright 2024 Daily Creative Co., Ltd. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
const themeModeEnum = {
  DARK: 'DARK',
  DEFAULT: 'DEFAULT',
}
export default {
  computed: {
    themeMode() {
      if (this.$route && this.$route.name === 'project-_id') {
        return themeModeEnum.DARK
      }
      return themeModeEnum.DEFAULT
    },
    themeClass() {
      if (this.themeMode === themeModeEnum.DARK) {
        return {
          bg: 'bg-wl-bg',
          text: 'text-wl-white',
          ccText: 'text-wl-white-_70',
        }
      }
      return {
        bg: 'bg-wl-white',
        text: 'text-wl-dark',
        ccText: 'text-wl-grey-sec',
      }
    },
  },
}
</script>
