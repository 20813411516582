<template>
  <div class="floating-contact-buttons">
    <a
      href="https://m.me/104632445685888"
      target="_blank"
      class="contact-button facebook-messenger"
    >
      <img
        src="@/assets/images/07_Others/img_Messenger.svg"
        alt="Facebook Messenger"
      />
    </a>
    <a
      href="https://lin.ee/vCoQ9SJ"
      target="_blank"
      class="contact-button line"
    >
      <img src="@/assets/images/07_Others/img_LINE.svg" alt="LINE" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.floating-contact-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1000;
  background-color: transparent;
}

.contact-button {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transition: transform 0.2s ease-in-out, width 0.2s ease-in-out,
    height 0.2s ease-in-out;

  img {
    max-width: 100%;
  }
  &:hover {
    transform: scale(1.15);
  }
}
@media (max-width: 480px) {
  .floating-contact-buttons {
    bottom: 16px;
    right: 16px;
    gap: 16px;
  }
}
</style>
