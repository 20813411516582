<template>
  <div>
    <div class="relative">
      <div class="">
        <div
          class="relative z-10 font-medium bg-transparent text-wl-24-s wl-375:text-wl-28-s wl-480:text-wl-32-s wl-748:text-wl-40-s wl-1004:text-wl-48-s"
        >
          {{ $t('home.hero_1') }}
          <br />
          {{ $t('home.hero_2') }}
        </div>

        <img
          class="banner-default_description-box_decorative-image"
          src="@/assets/images/00_Home/home_img_line.svg"
          alt="Banner Text Decorative Image"
        />
      </div>
      <!-- <img
        class="banner-default_description-box_decorative-image"
        src="@/assets/images/00_Home/home_img_line.svg"
        alt="Banner Text Decorative Image"
      /> -->
    </div>
    <div
      class="relative z-10 bg-transparent text-wl-14-l wl-375:text-wl-16-l mt-12px wl-375:mt-[25px] wl-480:mt-[31px] wl-748:mt-16px text-wl-white-_70"
    >
      {{ $t('home.hero_sub_1') }}
      <br class="hidden wl-748:inline" />
      {{ $t('home.hero_sub_2') }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.banner-default_description-box_decorative-image {
  position: absolute;
  width: 99px;
  height: 22px;
  top: 3.75rem;
  left: 7.5rem;
  z-index: 1;
}
@media screen and (min-width: 375px) {
  .banner-default_description-box_decorative-image {
    width: 116px;
    height: 27px;
    top: 4.25rem;
    left: 8.75rem;
  }
}
@media screen and (min-width: 480px) {
  .banner-default_description-box_decorative-image {
    width: 131px;
    height: 30px;
    top: 4.75rem;
    left: 9.875rem;
  }
}
@media screen and (min-width: 748px) {
  .banner-default_description-box_decorative-image {
    width: 147px;
    height: 33px;
    top: auto;
    bottom: -1rem;
    left: 13.45rem;
  }
}
@media screen and (min-width: 1004px) {
  .banner-default_description-box_decorative-image {
    width: 147px;
    height: 33px;
    top: auto;
    bottom: -1rem;
    left: 18rem;
  }
}
</style>
