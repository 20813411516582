<template>
  <div>
    <div
      class="font-bold text-wl-20-s wl-375:text-wl-24-s wl-480:text-wl-28-s wl-748:text-wl-32-l"
    >
      {{ $t('home.portfoilo_works_list') }}
    </div>
    <div
      class="text-wl-white-_70 text-wl-14-l wl-375:text-wl-16-l mt-1 wl-480:mt-2"
    >
      {{ $t('home.portfoilo_works_subtitle') }}
    </div>
    <div
      class="flex flex-col mt-3 wl-375:mt-7 wl-480:mt-8 wl-1004:mt-[3.75rem]"
    >
      <div
        v-for="(projectConfig, projectConfigIndex) in projectConfigs"
        :key="`home_spotlight-project-${projectConfigIndex}`"
        class="flex flex-col wl-1004:items-center wl-1004:justify-between"
        :class="[
          projectConfigIndex === projectConfigs.length - 1
            ? ''
            : 'mb-5 wl-375:mb-6 wl-480:mt-7 wl-748:mb-8 wl-1004:mb-[7.5rem] wl-1420:mb-[6.25rem]',
          projectConfigIndex % 2 === 0
            ? 'wl-1004:flex-row'
            : 'wl-1004:flex-row-reverse',
        ]"
      >
        <img
          :src="projectConfig.imgSrc"
          :alt="`${projectConfig.name} Image`"
          class="home_spotlight-project-project-image"
        />
        <div class="home_spotlight-project-project-text-block">
          <div
            class="text-wl-primary text-wl-14-l mt-3 wl-375:mt-4 wl-748:mt-5 wl-1004:mt-0"
          >
            {{ projectConfig.name }}
          </div>
          <div
            class="font-bold wl-748:mt-[0.125rem] text-wl-16-s wl-375:text-wl-20-s wl-480:text-wl-24-s wl-748:text-wl-28-s"
          >
            {{ projectConfig.title }}
          </div>
          <div
            :class="`text-wl-white-_70 text-wl-14-l wl-375:text-wl-16-l mt-2 wl-748:mt-3`"
          >
            {{ projectConfig.description }}
          </div>
          <router-link
            :to="projectConfig.to"
            :class="`
              wl-transition-default hover:brightness-75 w-fit flex items-center
              mt-3 wl-375:mt-4 wl-480:mt-5  wl-748:mt-6 wl-1004:mt-8 wl-1420:mt-6
            `"
            style="transform: translateZ(0)"
          >
            <div
              class="text-wl-white-_70 font-medium text-wl-14-l wl-375:text-wl-16-l"
            >
              {{ $t('home.portfoilo_works_learn_more') }}
            </div>
            <IconJumpPage
              class="ml-1 w-4 h-4 wl-375:w-5 wl-375:h-5 fill-wl-white-_70"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import IconJumpPage from '@/components/svg/icon/IconJumpPage'

const i18n = useI18n()
const projectConfigs = computed(() => [
  {
    name: i18n.t('home.docs4docs_the_englis_name'),
    title: i18n.t('home.docs4docs_the_englis_title'),
    description: i18n.t('home.docs4docs_the_englis_description'),
    imgSrc: require('@/assets/images/00_Home/home_img_docs4docs@2x.jpg'),
    to: { name: 'project-_id', params: { id: 'Docs4Docs' } },
  },
  {
    name: i18n.t('home.systex_ecommerce_int_name'),
    title: i18n.t('home.systex_ecommerce_int_title'),
    description: i18n.t('home.systex_ecommerce_int_description'),
    imgSrc: require('@/assets/images/00_Home/home_img_systex@2x.jpg'),
    to: { name: 'project-_id', params: { id: '精誠大電商服務網站' } },
  },
  {
    name: i18n.t('home.pawbo_life_the_inter_name'),
    title: i18n.t('home.pawbo_life_the_inter_title'),
    description: i18n.t('home.pawbo_life_the_inter_description'),
    imgSrc: require('@/assets/images/00_Home/home_img_pawbo@2x.jpg'),
    to: { name: 'project-_id', params: { id: 'Pawbo Life' } },
  },
])
</script>
<style lang="scss" scoped>
.home_spotlight-project-project-text-block {
  width: 100%;
}
@media screen and (min-width: 1004px) {
  .home_spotlight-project-project-text-block {
    width: 46%;
  }
}
@media screen and (min-width: 1420px) {
  .home_spotlight-project-project-text-block {
    width: 40.5%;
  }
}
</style>
<style lang="scss" scoped>
.home_spotlight-project-project-image {
  width: 100%;
  height: 68%;
}
@media screen and (min-width: 1004px) {
  .home_spotlight-project-project-image {
    /* width: 464px; */
    width: 49%;
    height: auto;
  }
}
/* @media screen and (min-width: 1420px) {
  .home_spotlight-project-project-image {
    width: 588px;
    height: 400px;
  }
} */
</style>
