<template>
  <div class="overflow-hidden relative flex">
    <DescriptionBox
      :class="`
            mx-16px wl-748:mx-32px wl-1004:mx-40px wl-1420:mr-0  wl-1420:ml-[calc(50vw-600px)]
            mt-32px wl-375:mt-40px wl-480:mt-[60px] wl-748:mt-[80px] wl-1420:mt-[349px]
        `"
    />
    <img
      :class="`
        absolute 
        w-[288px] h-[184px] wl-375:w-[341px] wl-375:h-[218px] wl-480:w-[450px] wl-480:h-[288px]
        wl-748:w-[704px] wl-748:h-[450px] wl-1004:w-[816px] wl-1004:h-[522px] wl-1420:w-[1000px] wl-1420:h-[640px]
        left-1/2 translate-x-[-50%] wl-1420:left-auto wl-1420:translate-x-0 wl-1420:right-[-432px] wl-1900:right-[-192px] 
        bottom-20px wl-375:bottom-30px wl-480:bottom-[34px] wl-748:bottom-[17px] wl-1004:bottom-36px wl-1420:top-1/2 wl-1420:translate-y-[-50%]
      `"
      src="@/assets/images/00_Home/home_img_banner.svg"
      alt="Platform Image"
    />
    <img
      class="hidden wl-1420:block absolute bottom-32px left-1/2 translate-x-[-50%]"
      src="@/assets/images/00_Home/home_img_scroll.svg"
      alt="Scroll Prompt Image"
    />
  </div>
</template>
<script>
import DescriptionBox from './DescriptionBox'
export default {
  components: { DescriptionBox },
}
</script>
